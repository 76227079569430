export default {
  text001: 'Previous',
  text002: 'Next person',
  text003: 'Filtered',
  text004: 'To be filtered',
  text005: 'Expired',
  text006: 'Return',
  text007: 'CV information',
  text008: 'AI video interviewOnline',
  text009: 'Online written test',
  text010: 'Cognitive ability',
  text011: 'Professional personality',
  text012: 'Meritas-mental health',
  text013: 'Code exam',
  text014: 'PDP test report',
  text015: 'The third party’s assessment',
  text016: 'Offline interview results',
  text017: 'Arrange interview',
  text018: 'Remark',
  text019: 'Additional Information',
  text020: 'The candidate has interviewed {count} times in total',
  text021: 'AI video recording',
  text022: 'AI report',
  text023: 'Written test report',
  text0231: 'Reading Question',
  text024: 'Essay questions',
  text025: 'Objective question',
  text026: 'Choose a language',
  text027: '{0} years old',
  text028: 'L4-total score',
  text029: 'Generating',
  text030: 'Model training',
  text031: 'L2-total score',
  text032: 'Video manual total score',
  text033: 'Assessment score',
  text034: '{0} points',
  text035: 'L2-AI expression',
  text036: 'L2-AI professional image',
  text037: 'L2-AI sound',
  text038: 'Discourse semantics',
  text039: 'Candidates have not yet been invited for an AI interview',
  text040: 'Candidates have not yet completed the AI interview',
  text041: 'Resume parsing',
  text042: 'You do not have permission to view',
  text043: 'Programming questions',
  text044: 'Forward',
  text045: 'Evaluation record',
  text046: 'Pass',
  text047: 'Passed',
  text048: 'To be determined',
  text049: 'Pending',
  text050: 'Reject',
  text051: 'Rejected',
  text052: 'Refuse to interview',
  text053: 'Interview',
  text054: 'Please input your email',
  text055: 'Mail',
  text056: 'Confirm',
  text057: 'You have passed your resume',
  text058: 'Whether to set the interview time now',
  text059: 'Do not remind again',
  text060: 'Not set yet',
  text061: 'Set time',
  text062: '简体中文',
  text063: '繁体中文',
  text064: 'Already the first candidate',
  text065: 'Already the last candidate',
  text066: 'Original resume',
  text067: 'Standard resume',
  text068: 'Application Registration Form',
  text069: 'Successful operation',
  text070: 'Operation failed',
  text071: 'Link is dead',
  text072: 'Forwarded successfully',
  text073: 'Share success',
  text074: 'E-mail format is incorrect',
  text075: 'Candidates have no interview video available',
  text076: 'Candidates have not yet completed the AI assessment',
  text077: 'Report summary',
  text078: 'Video interview',
  text079: 'Custom test questions',
  text080: 'Mock programming',
  text081: 'Male',
  text082: 'Female',
  text083: 'Unknown',
  text084: 'Anti-cheating record',
  text085: 'undergraduate',
  english: 'L2-English',
  应届生: 'Graduates',
  初中及以下: 'Junior high school and below',
  highSchool: 'H.S./Technical School',
  '⼤专': 'Junior college',
  本科: 'Bachelor',
  硕士: 'Master',
  博士: 'Doctor',
  不限: 'Unlimited',
  text086: 'L5-AI score',
  text087: 'L2-AI score',
  // english: 'L2-English',
  text088: 'Anti-cheating log',
  text090: 'Total score of English',
  meritas: 'MeriTas evaluation',
  wqbsr: 'Completely incompetent',
  bushengren: 'Incompetent',
  shengren: 'Competent',
  chaochuqiwang: 'Exceed expectations',
  youxiu: 'Excellent',
  text100: 'Composite total score',
  text100_1: 'Cantonese score',
  text100_2: 'English score',

}
