<template>
  <van-row type="flex" class="question-wrapper comlun">
    <div class="question-content">
      <HtmlParse :curqes="currentQuestion" :htmlStr="currentQuestion.question.problem" :questionIndex="currentQuestionIndex + 1" :label="questionLabel" :totalVideoFlag.sync="totalVideoFlag" />
    </div>
    <!-- <div style="margin: 10px 10px 10px 0px">
      <span>{{ $t('com.report.interviewRecord.text001') }}：</span>
    </div> -->
    <div v-if="currentQuestion.video_url && currentQuestion.video_url.length">
      <div style="position: relative; width: 100%">
        <video-player
          class="player vjs-big-play-centered"
          :playsinline="true"
          @play="onPlay"
          :options="
            getPlayerOption(
              currentQuestion.video_url[0].video_url_mp4 ? currentQuestion.video_url[0].video_url_mp4 : currentQuestion.video_url[0].video_url,
              currentQuestion.video_url[0].video_screenshot_url
            )
          "
        ></video-player>
        <div class="small-tips" v-if="noWifi && answerVideoTips[i]">{{ $t('com.report.interviewRecord.text002') }}</div>
        <div class="noWifiTip" @click="showAnswerVideoSmallTips(i)" v-if="noWifi && totalVideoFlag">
          <div class="text">{{ $t('com.report.interviewRecord.text002') }}</div>
          <div class="paly-btn">
            <img src="@/assets/play.svg" />
          </div>
        </div>
      </div>
    </div>
    <div v-else style="margin-left: 10px">{{ $t('com.report.interviewRecord.text003') }}</div>
    <AnswerText style="margin-top: 16px" v-if="isOpenL2 && problem" :answerText="problem.answerText" :is-custom="problem.isCustom" :keyPointInfoResult="KPIR(problem)" :language="problem.language" :assessmentPoint="problem.assessmentPoint" />
    <div class="single-problem-estimate-wrapper" v-if="false">
      <div class="suggerlog-wrapper">
        <div v-if="!isProbe || (isProbe && problem && problem.isShowCompetency === 1)" class="rate-log_item">
          {{ $t('com.report.interviewRecord.text004') }}：
          <span v-if="problem && problem.assessmentPoint">{{ problem.assessmentPoint }}</span>
        </div>
        <div class="rate-log_item" v-else>
          {{ $t('com.report.interviewRecord.text048') }}：<span v-if="problem && problem.nlpValue">{{ problem.nlpValue }}</span>
        </div>

        <div class="rate-log_item flex-bt">
          <div class="l2-ai">
            <div class="label">{{ $t('com.report.interviewRecord.text006') }}：</div>
            <template v-if="aiMarkCfg.singleStatus && !l2status && problem && problem.calculateStar > -1">
              <van-rate
                v-model="problem.calculateStar"
                color="#ffd21e"
                :size="12"
                void-icon="star"
                :gutter="0"
                void-color="#eee"
                readonly
                :count="aiData.isL5Position === 1 ? 3 : 5"
              />
              <span class="huase" v-if="problem.calculateStar == 1">{{ $t('com.report.interviewRecord.text51') }}</span>
              <span class="huase" v-if="problem.calculateStar == 2">{{ $t('com.report.interviewRecord.text52') }}</span>
              <span class="huase" v-if="problem.calculateStar == 3">{{ $t('com.report.interviewRecord.text53') }}</span>
            </template>
            <span v-else-if="l2status">{{ $t('com.report.interviewRecord.text007') }}</span>
            <van-rate v-else :value="0" color="#ffd21e" :size="12" void-icon="star" :gutter="0" void-color="#eee" readonly :count="aiData.isL5Position === 1 ? 3 : 5"></van-rate>
          </div>
          <div @click="viewDetail(currentQuestion)" v-if="aiMarkCfg.singleStatus && isL2ScoreStop" class="detail">
            {{ currentQuestion.viewVisible ? $t('com.report.interviewRecord.text008') : $t('com.report.interviewRecord.text009') }}
          </div>
        </div>
        <!-- 文本相似度 -->
        <div v-if="antiCheatingInfo.checkTextRepetition == 1" class="rate-log_item justify-bt">
          <div class="info">
            <span>{{ $t('com.report.interviewRecord.text049') }}</span>
            <span>{{ getTextRate('maxWordRate', currentQuestion) }}%</span>
          </div>
          <div class="info">
            <span>{{ $t('com.report.interviewRecord.text050') }}</span>
            <span>{{ getTextRate('maxAiRate', currentQuestion) }}%</span>
          </div>
        </div>
        <div class="suggerlog" v-for="(l, index) of currentQuestion.logList" :key="index">
          <div class="line1">
            <i18n path="com.report.interviewRecord.text010" tag="div" class="left">
              <div place="name" class="name">{{ l.createByName }}</div>
              <van-rate place="score" v-model="l.estimateScore" color="#ffd21e" :size="12" void-icon="star" :gutter="0" void-color="#eee" readonly />
            </i18n>
            <div class="right">{{ moment(l.createDate).format('YYYY-MM-DD HH:mm:ss') }}</div>
          </div>
          <div class="line2">
            <i18n path="com.report.interviewRecord.text011" tag="span">
              <span place="name"></span>
              <span place="score" class="text">{{ l.estimateOpinion }}</span>
            </i18n>
          </div>
        </div>
      </div>
    </div>
    <div @click="viewDetail(currentQuestion)" v-if="isL2ScoreStop && aiMarkCfg.singleStatus" class="detail">
      {{ currentQuestion.viewVisible ? $t('com.report.interviewRecord.text55') : $t('com.report.interviewRecord.text54') }}
      <i class="el-icon-arrow-up content-arrow" :class="{ 'is-expand': !currentQuestion.viewVisible }" />
    </div>
    <div class="popover-content" v-if="isL2ScoreStop && currentQuestion.viewVisible">
      <div class="l2-item">
        <div class="l2-label">
          {{ $t('com.report.interviewRecord.text012') }}:
          <span v-if="problem && problem.assessmentPoint">{{ problem.assessmentPoint }}</span>
        </div>
        <!-- <div v-if="problem && problem.assessmentDesc" class="l2-comment">
          {{ problem.assessmentDesc }}
        </div> -->
      </div>
      <div class="weight" v-if="problem?.calculateRatio >= 0">
        <template>
          {{ $t('com.report.reportV3.text064') }}：<span>{{ problem?.calculateRatio }}%</span>
        </template>
      </div>
      <div class="l2-item" v-for="(score, index) in getL2Dimensions(problem)" :key="index">
        <div class="l2-label font14 mb8">
          {{ score.label }}：<van-rate
            v-if="!l2status && score[score.scoreKey]"
            :value="score[score.scoreKey]"
            color="#ffd21e"
            :size="15"
            void-icon="star"
            :gutter="0"
            void-color="#eee"
            readonly
          ></van-rate>
          <span v-else-if="l2status">{{ $t('com.report.interviewRecord.text007') }}</span>
          <van-rate v-else :value="0" color="#ffd21e" :size="15" void-icon="star" :gutter="0" void-color="#eee" readonl></van-rate>
        </div>
        <div class="l2-label font14">
          {{ $t('com.report.interviewRecord.text013') }}:
          <span>{{ score.info }}</span>
        </div>
      </div>
      <div v-if="!(getL2Dimensions(problem) && getL2Dimensions(problem).length)">{{ $t('com.report.interviewRecord.text014') }}</div>
    </div>
  </van-row>
</template>

<script>
import { markShowComprehensiveEstimate } from '@/api/report'
import moment from 'moment'
import HtmlParse from '@/components/HtmlParse'
import AnswerText from '@/components/answer-text'
import { l2Dimensions } from '@/assets/js/keyToName'
import i18n from '@/utils/i18n'
export default {
  components: { HtmlParse, AnswerText },
  inject: {
    provideInfo: { value: 'provideInfo', default: null },
  },
  props: {
    currentQuestion: {
      type: Object,
      default() {
        return {}
      },
    },
    currentQuestionIndex: {
      type: Number,
      default: 0,
    },
    questionLabel: {
      type: String,
      default: '',
    },
    isProbe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      estimateScore: 0,
      moment: moment,
      // currentQuestion: { question: {}, video_url: [{}] }, //主观题区域题目
      currentQuestionCode: { question: {}, video_url: [{}] }, // 编程题区域题目
      sleep: false,

      answerVideoTips: [],
      showAnswerVideoSmallTipsFlag: false,
      totalVideoFlag: true,
      videoEstimateLogList: [],
      l2Dimensions: l2Dimensions,
    }
  },
  created() {
    this.getManualVideoEstimateLogList()
  },
  mounted() {
    let problem_yt = this.answerInfos.find(p => {
      return this.problem?.parentId === p?.problemAnswerId
    })
    if (problem_yt) {
      this.problem.calculateRatio = problem_yt?.calculateRatio
    }
  },

  watch: {},

  methods: {
    viewDetail(currentQuestion) {
      currentQuestion.viewVisible = !currentQuestion.viewVisible
    },
    getL2Dimensions(problem) {
      const languages = [
        //
        '',
        i18n.t('com.report.interviewRecord.text024'),
        i18n.t('com.report.interviewRecord.text025'),
      ]
      const infos = [
        //
        '',
        i18n.t('com.report.interviewRecord.text026'),
        i18n.t('com.report.interviewRecord.text027'),
      ]
      if (problem) {
        const arr = this.l2Dimensions.map(item => {
          const keys = item.keys
          const obj = {}
          keys.forEach(k => {
            obj[k] = problem[k]
          })
          let label = ''
          let info = ''
          if (item.key === 'language') {
            label = languages[obj[item.key]]
            info = infos[obj[item.key]]
          } else {
            label = item.label
            info = item.info
          }

          return { ...item, ...obj, label, info }
        })
        return arr.filter(i => {
          return i[i.key] //找到开启的维度
        })
      } else {
        return []
      }
    },
    KPIR(problem) {
      if (problem) {
        const kpir = (problem?.keyPointInfoResult ? JSON.parse(problem?.keyPointInfoResult) : []).filter(item => {
          return item.num && item.num > 0
        })

        return this.getUniArr(kpir)
      } else {
        return []
      }
    },
    getUniArr(arr) {
      const a = []
      arr.forEach(i => {
        const index = a.findIndex(k => {
          return k.keyPoint === i.keyPoint
        })
        if (index === -1) {
          a.push(i)
        }
      })
      return a
    },

    onPlay() {
      const videoArray = document.getElementsByTagName('video')
      if (videoArray && videoArray.length) {
        for (let i = 0; i < videoArray.length; i++) {
          videoArray[i].onplay = () => {
            for (let j = 0; j < videoArray.length; j++) {
              if (i !== j) {
                videoArray[j].pause()
              }
            }
          }
        }
      }
    },
    getPlayerOption(url, poster) {
      return {
        width: '100%',
        height: 165,
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{ type: 'video/mp4', src: url }],
        poster: poster, //require('./images/banner-poster.jpg'), // 封面地址
        notSupportedMessage: i18n.t('com.report.interviewRecord.text028'), // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      }
    },
    getManualVideoEstimateLogList() {
      markShowComprehensiveEstimate({ interviewId: this.interviewId, estimateType: 2 }).then(res => {
        if (res && res.code === 0) {
          this.videoEstimateLogList = res.data
        }
      })
    },

    showAnswerVideoSmallTips(index) {
      if (!this.totalVideoFlag) {
        return
      }
      this.totalVideoFlag = false

      this.$set(this.answerVideoTips, index, true)
      this.$refs['answerVideo' + index][0].play()

      this.showAnswerVideoSmallTipsFlag = true

      setTimeout(() => {
        this.$set(this.answerVideoTips, index, false)
      }, 5000)
    },
    // 获取文本相似度
    getTextRate(similarType, question = {}) {
      // 当前题目的文本相似度内容
      const similarArr = this.antiCheatingInfo.textSimilarityRes || []
      const similarInfo = similarArr.find(item => item.questionId === question?.question?.question_id) || {}
      return similarInfo[similarType] || 0
    },
  },

  computed: {
    aiMarkCfg() {
      return this.$store.state.aiMarkCfg
    },
    l2status() {
      //0已出分 1 出分中
      return this.l2ReportInfo?.l2status
    },
    answerInfos() {
      return this.l2ReportInfo?.answerInfos || []
    },

    isOpenL2() {
      const isOrderAi = this.aiData?.isOrderAi || this.$store.state.companyInfo?.isOrderAi
      return isOrderAi === 2 || isOrderAi === 3
    },

    l2ReportInfo() {
      return this.aiData?.l2ReportInfo
    },

    aiData() {
      return this.$store.state.aiData
    },
    isL2ScoreStop() {
      return this.$store.state.interviewMsg.isL2ScoreStop !=1
    },
    showManualEstimateDialog() {
      return this.$store.state.showManualEstimateDialog
    },
    interviewId() {
      return this.$store.state.interviewId
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    answer_method() {
      return this.currentQuestion.question.problem_answer_method
    },
    noWifi() {
      return this.$store.state.noWifi
    },
    // 防作弊记录
    antiCheatingInfo() {
      return this.provideInfo.antiCheatingInfo || {}
    },

    problem() {
      return this.answerInfos.find(p => {
        return this.currentQuestion?.id === p?.problemAnswerId
      })
    },
  },
  filters: {
    filterResult(val) {
      if (val === 1) {
        return i18n.t('com.report.interviewRecord.text031')
      } else if (val === 2) {
        return i18n.t('com.report.interviewRecord.text032')
      } else if (val === 3) {
        return i18n.t('com.report.interviewRecord.text033')
      }
    },
    RightKey(rightKey) {
      if ('true' === rightKey) {
        return i18n.t('com.report.interviewRecord.text034')
      } else if ('false' === rightKey) {
        return i18n.t('com.report.interviewRecord.text035')
      }
      return rightKey
    },
    rightText(key) {
      switch (key) {
        case 1:
          return i18n.t('com.report.interviewRecord.text036')
        case 2:
          return i18n.t('com.report.interviewRecord.text037')
        default:
          return i18n.t('com.report.interviewRecord.text038')
      }
    },
  },
}
</script>

<style  lang="scss" scoped>
video {
  width: 100%;
  height: 200px;
  margin: 10px 0;
}

.sub-title {
  position: relative;
}
.noWifiTip {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 220px;

  .text {
    color: #ffffff;
  }
  .paly-btn {
    margin-top: 10px;
    color: #ffffff;
    width: 30px;
    img {
      width: 100%;
    }
  }
}
.inner-video-player {
  position: relative;
  width: 100%;
}
.small-tips {
  position: absolute;
  bottom: 20%;
  left: 0;
  color: #ffffff;
  z-index: 10;
  animation-name: enterIn;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes enterIn {
  0% {
    transform: translateX(-1000vw);
  }
  10% {
    transform: translateX(0);
  }
  95% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
video {
  width: 100%;
  height: 200px;
  margin: 10px 0;
}
.score {
  padding: 0 20px;
  div {
    color: #666;
  }
  .test {
    margin: 10px 0;
    line-height: 20px;
  }
}
section {
  .gray {
    color: #666666;
  }
  .green {
    color: #6ed401;
  }
  .red {
    color: #ff0000;
  }
  .yellow {
    color: #f4d75e;
  }
  .line-20 {
    line-height: 20px;
  }
  .video {
    width: 100%;
    height: 200px;
    margin: 10px 0;
  }
  pre {
    word-break: break-word;
    white-space: pre-wrap;
  }

  /deep/ .van-tabs {
    .van-tabs__line {
      background: #1890ff;
    }
    .van-tab--active {
      font-weight: 550;
    }
    .van-tabs__content {
      padding-bottom: 40px;
    }
  }
  .comlun {
    flex-direction: column;
    justify-content: space-between;
  }
  /deep/ .obj-pagination {
    justify-content: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    .van-pagination__page {
      flex-grow: 1;
    }
    li:last-child {
      display: none;
    }
    li:first-child {
      display: none;
    }
    li {
      cursor: pointer;
      //display: block;
      width: 40px;
      height: 40px;
      border-radius: 3px;
      margin: 0 8px;
      border: 1px solid rgba(221, 221, 221, 1);
    }
  }
  .video-title {
    padding: 0 20px;
  }
  .obj-title {
    // padding: 0 20px;
    font-size: 12px;
    color: #666666;
  }
  .obj-answer {
    // margin: 0 20px;
    border: 1px dashed #dddddd;
    border-radius: 5px;
    padding: 12px 16px;
    padding: 14px;
    div:first-child {
      color: #333333;
      font-weight: 500;
      // font-size: 14px;
      // margin-bottom: 10px;
    }
  }
  .sub-answer {
    border: 1px dashed #dddddd;
    border-radius: 5px;
    padding: 12px 16px;
    margin-top: 10px;
    div:first-child {
      color: #333333;
      font-weight: 500;
      // font-size: 14px;
      // margin-bottom: 10px;
    }
  }
  /deep/.van-panel {
    .van-panel__header {
      padding-top: 14px;
      padding-bottom: 14px;
      .van-cell__title {
        font-size: 15px;
        color: #333333;
        font-weight: 550;
      }
    }
    .van-panel__content {
      padding: 0 20px;
    }
  }
  /deep/ .van-panel::after {
    content: none;
  }

  .single-problem-estimate-wrapper {
    margin-top: 0.2rem;
    .single-problem-estimate {
      border: 1px solid #cccccc;
      border-radius: 5px;
      padding: 0.2rem 0.3rem;
      .title {
        display: flex;
        align-items: center;
        .van-rate {
          margin-left: 0.4rem;
        }
      }
      /deep/ textarea {
        margin-top: 0.2rem;
        border: 1px solid #c8c9cc;
        border-radius: 4px;
        padding: 10px;
        transform: translateX(0.8);
        transform: translateX(10rpx);
        box-sizing: border-box;
      }
      .btn-list {
        width: 100%;
        display: flex;
        color: #ffffff;
        margin-top: 0.3rem;
        text-align: center;
        .submit {
          padding: 0.2rem 0;
          width: 2rem;
          background-color: #00aaff;
          border-radius: 5px;
        }

        .cancle {
          border-radius: 5px;
          padding: 0.2rem 0;
          width: 2rem;
          margin-left: 0.5rem;
          background-color: #00aaff;
        }
      }
    }

    .suggerlog-wrapper {
      margin-top: 0.2rem;
      border: 1px dashed #dddddd;
      border-radius: 5px;
      padding: 12px 16px;
      .suggerlog {
        padding: 0.2rem 0;
        .line1 {
          display: flex;
          justify-content: space-between;

          .left {
            display: flex;
            align-items: center;
            .name {
              max-width: 1.7rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: 600;
            }

            .dafen {
              margin-left: 0.1rem;
              //color: #cccccc;
            }
          }
        }
        .line2 {
          margin-top: 0.2rem;
        }
      }
      .rate-log_item {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        position: relative;
        &.justify-bt {
          justify-content: space-between;
        }
        .l2-ai {
          display: flex;
          align-items: center;
          margin-right: 20px;
          .label {
            width: 65px;
          }
          .huase {
            font-weight: 500;
            color: #FFCA3A;
            margin-left: 5px;
          }
        }
        .detail {
          color: #1890ff;
          cursor: pointer;
        }
      }
      .popover-content {
        .l2-item + .l2-item {
          margin-top: 10px;
        }
        .weight {
          font-weight: 400;
          margin: 3px 0 10px;
          color: #1890ff;
        }
      }
    }
  }
  .question-wrapper {
    // padding: 0 0.4rem;
    /deep/ img {
      max-width: 100%;
    }
    .video {
      max-width: 100%;
    }

    .question-content {
      display: flex;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      line-height: 24px;
    }
  }
  .detail {
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    line-height: 20px;
    margin-top: 8px;
    .content-arrow {
      margin-left: 6px;
      transition: transform 0.3s;
      &.is-expand {
        transform: rotate(180deg);
      }
    }
  }
  .popover-content {
    margin-top: 12px;
    .l2-item + .l2-item {
      margin-top: 12px;
    }
    .l2-label {
      font-weight: 400;
      font-size: 16px;
      color: #6D7278;
      line-height: 20px;
    }
    .font14 {
      font-size: 14px;
      .van-rate {
        margin-left: 5px;
      }
    }
    .mb8 {
      margin-bottom: 8px;
    }
    .weight {
      font-weight: 400;
      margin: 3px 0 10px;
      color: #1890ff;
    }
  }

  .manmual-total-esimate {
    padding: 0.6rem 0.4rem;
    .title {
      color: #14b1ff;
      text-align: center;
      font-size: 0.4rem;
      font-weight: 600;
    }
    .desc {
      padding: 0.2rem 0;
      text-align: left;
      font-size: 0.35rem;
    }

    .radio-wrapper {
      margin-top: 0.2rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .radio-item {
        font-size: 0.3rem;
        width: 26vw;
        padding: 0.2rem 0;
        text-align: center;
        border: 1px solid #dcdfe6;
      }

      .active {
        background-color: #14b1ff;
        color: #ffffff;
      }
    }
    .estimate-row {
      margin-top: 0.3rem;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .van-rate {
        margin-left: 0.2rem;
      }
      .submit-btn {
        cursor: pointer;
        padding: 0.2rem;
        width: 2rem;
        color: #ffffff;
        background-color: #00aaff;
        text-align: center;
        font-size: 0.4rem;
      }
    }

    .textarea-wrapper {
      width: 100%;
      box-sizing: border-box;
      /deep/ textarea {
        margin-top: 0.2rem;
        border: 1px solid #c8c9cc;
        border-radius: 4px;
        padding: 10px;
        transform: translateX(0.8);
        transform: translateX(10rpx);
        box-sizing: border-box;
      }
    }

    .btn-list {
      width: 100%;
      display: flex;
      color: #ffffff;
      margin-top: 0.3rem;
      text-align: center;
      .submit {
        padding: 0.2rem 0;
        width: 2rem;
        background-color: #00aaff;
        border-radius: 5px;
      }

      .cancle {
        border-radius: 5px;
        padding: 0.2rem 0;
        width: 2rem;
        margin-left: 0.5rem;
        background-color: #00aaff;
      }
    }
    .suggerlog-wrapper {
      margin-top: 0.2rem;
      border: 1px dashed #dddddd;
      border-radius: 5px;
      padding: 12px 16px;
      .suggerlog {
        padding: 0.2rem 0;
        .result-row {
          //margin-top: 0.2rem;
          font-weight: 600;
        }
        .line1 {
          margin-top: 0.2rem;
          display: flex;
          justify-content: space-between;

          .left {
            display: flex;
            align-items: center;
            .name {
              max-width: 1.7rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: 600;
            }

            .dafen {
              margin-left: 0.1rem;
              font-weight: 600;
              //color: #cccccc;
            }
          }
        }
        .line2 {
          margin-top: 0.2rem;
          .pingjia {
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.player {
  width: 100%;
  .video-js {
    width: 100%;
    height: 200px;
    video {
      width: 100%;
    }
    .vjs-big-play-button {
      display: none;
    }
    .vjs-control-bar {
      display: flex;
      visibility: visible;
      opacity: 1;
    }
  }
}

.obj-answer {
  pre {
    p {
      margin: 0 !important;
    }
  }
}
</style>
