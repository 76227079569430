<template>
  <section class="soft__skills" v-if="aiData && showSoftSkill">
    <template v-if="aiMarkCfg.allStatus">
    <!-- <div class="part-title">软技能</div> -->
    <!-- <div class="line1">{{ $t('com.report.reportV3.text001') }}</div> -->
    <!-- <div class="ai__score__title">AI打分</div> -->
    <!-- <div class="ai__score__desc">{{ $t('com.report.reportV3.text002', { bq: BQ, sy: SY }) }}</div> -->
    <div class="estimate-group" style="display: none;">
      <div class="estimate-score-wrapper" v-if="aiData.isShowAI !== 2">
        <div class="estimate-title">
          <template v-if="aiData.isL5Position">{{ $t('com.report.reportV3.text130') }}：</template>
          <template v-else>{{ $t('com.report.reportV3.text003') }}：</template>
        </div>
        <van-rate v-if="aiData.isL5Position && aiData.l5Star > 0" v-model="aiData.l5Star" :size="22" color="#ffd21e" :gutter="2" readonly />
        <template v-else-if="aiData.isL5Position && aiData.tdnaScore === -1">{{ $t('com.report.reportV3.text004') }}</template>
        <van-rate v-else-if="aiData.aiAllScore && aiData.aiAllScore > 0 && aiData.isShowAI && aiData.isAiModel > 0" :value="aiData.aiAllScore || 0" color="#ffd21e" :size="22" :gutter="2" readonly />
        <template v-else>
          <div class="mode-img" v-if="interviewResult.haveVideoQuestion === 1">
            <!-- <img src="@/assets/img/generating.png" v-if="aiData.isShowAI && aiData.isAiModel > 0" /> -->
            <div v-if="aiData.isShowAI && aiData.isAiModel > 0">{{ $t('com.report.reportV3.text004') }}</div>
            <!-- <img src="@/assets/img/dingzhi-mode.png" v-else /> -->
            <div v-else>{{ $t('com.report.reportV3.text005') }}</div>
          </div>
          <van-rate v-else :value="0" :size="22" color="#ffd21e" readonly />
        </template>
      </div>
      <div class="estimate-score-wrapper" v-if="aiData.isShowAI !== 2">
        <div class="estimate-title">
          <span v-if="aiData.isL5Position === 1">{{ $t('com.report.reportV3.text006') }}：</span>
          <span v-else> {{ isAnyongCompany ? $t('com.report.base.english') : $t('com.report.reportV3.text006') }}： </span>
        </div>
        <van-rate v-if="isOpenL2 && l2score > -1" :value="l2score || 0" color="#ffd21e" :size="22" :gutter="2" readonly />
        <template v-else>
          <div class="mode-img" v-if="interviewResult.haveVideoQuestion === 1">
            <!-- <img src="@/assets/img/generating.png" v-if="aiData.isShowAI && aiData.isAiModel > 0" /> -->
            <div v-if="l2status">{{ $t('com.report.reportV3.text004') }}</div>
            <!-- <img src="@/assets/img/dingzhi-mode.png" v-else /> -->
            <div v-else>-</div>
          </div>
          <van-rate v-else :value="0" :size="22" color="#ffd21e" readonly />
        </template>
      </div>
      <div class="estimate-score-wrapper">
        <div class="estimate-title">{{ $t('com.report.reportV3.text007') }}：</div>
        <van-rate v-if="aiData.estimateVideoScore && aiData.estimateVideoScore > 0" :value="aiData.estimateVideoScore || 0" color="#ffd21e" :size="22" :gutter="2" readonly allow-half />
        <van-rate v-else :value="aiData.estimateTotalScore || 0" color="#ffd21e" :size="22" :gutter="2" readonly allow-half />
      </div>
    </div>
    <div class="score-wrapper">
      <div class="desc">{{ $t('com.report.reportV3.text008') }}：</div>
      <div class="info">{{ $t('com.report.reportV3.text009') }}</div>
      <div
        v-if="
          aiData.tdnaScore && aiData.tdnaScore > 0 && (aiData.isL5Position === 1 || (aiData.isShowAI && aiData.isAiModel && interviewResult.haveVideoQuestion === 1 && +aiData.scoreConfigId !== 7))
        "
        class="process-wrapper"
      >
        <ProcessWrapper :score="aiData.tdnaScore" />
      </div>

      <div
        v-else-if="(aiData.isShowAI && aiData.isAiModel > 0 && interviewResult.haveVideoQuestion === 1 && +aiData.scoreConfigId !== 7) || (aiData.isL5Position === 1 && aiData.tdnaScore === -1)"
        class="process-wrapper"
      >
        {{ $t('com.report.reportV3.text004') }}
      </div>
      <div v-else-if="interviewResult.haveVideoQuestion === 1 || +aiData.scoreConfigId === 7" class="process-wrapper">{{ $t('com.report.reportV3.text005') }}</div>
      <div v-else class="process-wrapper">
        <ProcessWrapper :score="0" />
      </div>
      <div class="score-explain">{{ tdnaComments[aiData.tdnaScore - 1] }}</div>
    </div>
    <div v-if="(showL2Radar && !isL5Position) || (isL5Position && isPositiveInteger(l5Score))" class="l2-question" style="display: none;">
      <div class="l2-q_t p-l">
        <!-- {{ $t('com.report.reportV3.text010') }} -->
        <i18n path="com.report.reportV3.text010" tag="span">
          <span place="label">{{ isL5Position ? 'L5' : 'L2' }}</span>
        </i18n>
      </div>
      <PercentWrapper :sort="sort" />
      <div v-if="seriesData && seriesData.length && seriesData.length > 2" class="l2-q_r" id="scoreRadar"></div>
    </div>

    <div class="score-wrapper">
      <div class="desc">{{ isYJLCompany ? 'AI-' : 'L2-AI' }}{{ YZ }}{{ $t('com.report.reportV3.text011') }}：</div>
      <div class="info">{{ $t('com.report.reportV3.text012') }}</div>
      <div class="process-wrapper">
        <ProcessWrapper :score="aiData.beautyScore" />
      </div>
      <div class="score-explain">{{ beautyComments[aiData.beautyScore - 1] }}</div>
    </div>

    <div class="score-wrapper">
      <div class="desc">{{ isYJLCompany ? 'AI-' : 'L2-AI' }}{{ SY }}{{ $t('com.report.reportV3.text011') }}：</div>
      <div class="info">{{ $t('com.report.reportV3.text013') }}</div>
      <div class="process-wrapper">
        <ProcessWrapper :score="aiData.speechScore" />
      </div>
      <div class="score-explain">{{ speechComments[aiData.speechScore - 1] }}</div>
    </div>

    <div class="score-wrapper">
      <div class="desc">{{ isYJLCompany ? 'AI-' : 'L2-AI' }}{{ BQ }}{{ $t('com.report.reportV3.text011') }}：</div>
      <div class="info">{{ $t('com.report.reportV3.text014') }}</div>
      <div class="process-wrapper">
        <ProcessWrapper :score="aiData.emotionScore" />
      </div>
      <div class="score-explain">{{ emotionComments[aiData.emotionScore - 1] }}</div>
    </div>

    <template v-if="aiData.emotionScore">
      <!-- <div class="eCharts-content" v-if="emtionsArray && emtionsArray.length && aiData.emotionScore">
				<div id="emotion-eCharts"></div>
			</div> -->
      <template v-if="emtionsArray && emtionsArray.length">
        <div class="emition-total">
          <div v-for="(emition, index) in emtionsArray" :key="index" :style="{ width: `${emition.percent}%`, background: emition.color }" class="emition-item"></div>
        </div>
        <div class="emition-dots">
          <div class="dot-item" v-for="(emition, index) in emtionsArray" :key="index">
            <div :style="{ background: emition.color }" class="dot"></div>
            <div class="name">{{ `${emition.name} ${emition.percent}%` }}</div>
          </div>
        </div>
      </template>
      <template v-if="topemotion && topemotion.length">
        <div class="top-emotion-analysis">{{ $t('com.report.reportV3.text015') }}：</div>
        <div class="emotion-row" v-for="(emotionItem, index) in topemotion" :key="index">
          <div class="flexd">
            <div class="dot" :style="{ background: emotionItem.color }"></div>
            <div class="emotion-text">{{ emotionItem.emotionname }}</div>
          </div>
          <div class="emotion-text-desc">{{ emotionItem.emotiondesc }}</div>
        </div>
      </template>
    </template>
    </template>
    <L2Question style="display: none;" />
  </section>
</template>
<script>
import * as echarts from 'echarts'
import PercentWrapper from '@/components/reportV3/common/percent-wrapper.vue'
import ProcessWrapper from '@/components/reportV3/common/process-wrapper.vue'
import L2Question from '@/components/reportV3/l2-question.vue'
import i18n from '../../utils/i18n'

export default {
  components: { PercentWrapper, ProcessWrapper, L2Question },
  data() {
    return {
      showSoftSkill: false,
      tempData: null,
      tdnaComments: [
        i18n.t('com.report.reportV3.text016'),
        i18n.t('com.report.reportV3.text017'),
        i18n.t('com.report.reportV3.text018'),
        i18n.t('com.report.reportV3.text019'),
        i18n.t('com.report.reportV3.text020'),
      ],
      // answerInfos: [],
    }
  },

  computed: {
    aiMarkCfg() {
      return this.$store.state.aiMarkCfg
    },
    showL2Radar() {
      return this.isOpenL2 && this.l2status === 0 && this.answerInfos && this.answerInfos.length > 2
    },

    isL5Position() {
      return this.aiData.isL5Position
    },
    indicator() {
      return this.answerInfos.map(item => {
        const info = Array.isArray(item) ? item[0] : item
        const score = info.calculateStar.toFixed(0)
        return {
          //
          // name: `${item.assessmentPoint}(${score}星)`,
          name: `${info.assessmentPoint}(${i18n.t('com.report.reportV3.text059', [score])})`,
          max: this.isL5Position ? 3 : 5,
        }
      })
    },

    seriesData() {
      return this.answerInfos.map(item => {
        const info = Array.isArray(item) ? item[0] : item
        const score = info.calculateStar
        return score
      })
    },
    radar() {
      return {
        indicator: this.indicator,
        scale: true,
        axisTick: {
          //刻度设置
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#B1D8FF',
          },
        },
        axisName: {
          show: true,
          color: '#333',
          fontWeight: 'blod',
          width: 10,
          overflow: 'breakAll',

          rich: {
            width: 10,
            overflow: 'breakAll',
          },
        },
        splitNumber: this.isL5Position ? 3 : 5, //刻度
        splitLine: {
          //刻度连接线
          show: false,
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ['rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)'],
          },
        },
      }
    },
    series() {
      return [
        {
          type: 'radar',
          label: {
            show: false,
          },
          data: [
            {
              value: this.seriesData,
              // [4, 4, 4, 4, 1],
              areaStyle: {
                // color: '#409EFF',
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(90, 230, 191, 1)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(24, 144, 255, 1)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              lineStyle: {
                color: 'rgba(90, 230, 191, 1)',
                opacity: 1,
              },
              itemStyle: {
                color: '#409EFF',
              },
            },
          ],
        },
      ]
    },
    l2status() {
      return this.l2ReportInfo?.l2status
    },
    l2score() {
      return this.l2ReportInfo?.l2score || 0
    },
    l2ReportInfo() {
      return this.aiData?.l2ReportInfo
    },
    // sort() {
    //   return this.l2ReportInfo?.l2PercentageScore || 0
    // },
    isOpenL2() {
      const isOrderAi = this.aiData?.isOrderAi || this.$store.state.companyInfo?.isOrderAi
      return isOrderAi === 2 || isOrderAi === 3
    },

    sort() {
      return this.isL5Position ? this.l5RankPercent : this.l2PercentageScore
    },

    l2PercentageScore() {
      return this.l2ReportInfo?.l2PercentageScore || 0
    },

    l5RankPercent() {
      return (this.l5ScoreRank?.rankPercent || 0) * 100
    },

    l5Score() {
      return this.l5ScoreRank?.l5Score
    },

    l5ScoreRank() {
      return this.aiData.l5ScoreRank
    },
    aiData() {
      return this.$store.state.aiData
    },
    answerResult() {
      return this.$store.state.answerResult
    },
    topemotion() {
      if (this.aiData && this.aiData.emotionScores && this.aiData.emotionScores.topemotion) {
        const topemotion = this.aiData.emotionScores.topemotion.map(item => {
          return {
            ...item,
            color: this.emtionsArray.find(e => {
              return e.name.toLowerCase() === item.emotionname.toLowerCase()
            })?.color,
          }
        })
        console.log(topemotion, 333333333)
        return topemotion
        // return this.aiData.emotionScores.topemotion
      } else {
        return []
      }
    },
    emtionsArray() {
      if (this.aiData && this.aiData.emotionScores && this.aiData.emotionScores.emtions) {
        const emtions = this.aiData.emotionScores.emtions
        let total = 0
        emtions.forEach(item => {
          total += item.emotioncount
        })
        return this.aiData.emotionScores.emtions
          .map(item => {
            // emotioncount: 15
            // emotionname: "惊讶"
            let color = ''
            if (item.emotionname === '惊讶' || item.emotionname === 'Surprise' || item.emotionname === '驚訝') {
              color = '#C5C7DC'
            } else if (item.emotionname === '高兴' || item.emotionname === 'Happiness' || item.emotionname === '高興') {
              color = '#FFE679'
            } else if (item.emotionname === '中性' || item.emotionname === 'Neutral') {
              color = '#5FA4FF'
            } else if (item.emotionname === '难过' || item.emotionname === 'Sadness' || item.emotionname === '難過') {
              color = '#00E880'
            } else if (item.emotionname === '厌恶' || item.emotionname === 'Disgust' || item.emotionname === '厭惡') {
              color = '#FF4B19'
            } else if (item.emotionname === '愤怒' || item.emotionname === 'Anger' || item.emotionname === '憤怒') {
              color = '#6572F8'
            } else if (item.emotionname === '害怕' || item.emotionname === 'Fear' || item.emotionname === '害怕') {
              color = '#F5B919'
            }
            return {
              value: item.emotioncount,
              name: item.emotionname,
              color,
              percent: Number(((item.emotioncount / total) * 100).toFixed(2)),
            }
          })
          .filter(item => {
            return item.value !== 0
          })
          .sort((a, b) => {
            return b.value - a.value
          })
      } else {
        return []
      }
    },
    interviewResult() {
      return this.$store.state.interviewResult
    },
    isYJLCompany() {
      return this.$store.state.yjlCompanyId.includes(this.$store.state.companyId)
    },
    // 是否是安永公司
    isAnyongCompany() {
      const companyId = this.$store.state.companyId
      return this.$store.state.anyongCompanyId.includes(companyId) ? true : false
    },
    BQ() {
      return this.isYJLCompany ? ' BQ ' : i18n.t('com.report.reportV3.text021')
    },
    SY() {
      return this.isYJLCompany ? ' SY ' : i18n.t('com.report.reportV3.text022')
    },
    YZ() {
      return this.isYJLCompany ? ' YZ ' : i18n.t('com.report.reportV3.text023')
    },
    emotionComments() {
      return [
        i18n.t('com.report.reportV3.text024'),
        i18n.t('com.report.reportV3.text025'),
        i18n.t('com.report.reportV3.text026'),
        i18n.t('com.report.reportV3.text027'),
        i18n.t('com.report.reportV3.text028'),
      ]
    },
    speechComments() {
      return [
        i18n.t('com.report.reportV3.text029'),
        i18n.t('com.report.reportV3.text030'),
        i18n.t('com.report.reportV3.text031'),
        i18n.t('com.report.reportV3.text032'),
        i18n.t('com.report.reportV3.text033'),
      ]
    },
    beautyComments() {
      return [
        i18n.t('com.report.reportV3.text034'),
        i18n.t('com.report.reportV3.text035'),
        i18n.t('com.report.reportV3.text036'),
        i18n.t('com.report.reportV3.text037'),
        i18n.t('com.report.reportV3.text038'),
      ]
    },
    answerInfos() {
      const arrTmp = this.aiData.isL5Position ? this.l2ReportInfo?.dimLabelList : this.l2ReportInfo?.answerInfos
      const arr = (arrTmp || []).map(item => {
        const info = Array.isArray(item) ? item[0] : item

        return { ...info, visible: false }
      })
      const answerInfos = arr.sort((a, b) => {
        return a?.sortIndex - b?.sortIndex
      })

      return answerInfos
    },
  },
  watch: {
    answerResult: {
      immediate: true,
      deep: true,
      handler(val) {
        const flag = val.some(item => {
          const info = Array.isArray(item) ? item[0] : item
          // eslint-disable-next-line camelcase
          return info.question?.problem_answer_method === 1 || info.question?.problem_answer_method === 6
        })
        if (flag) {
          this.showSoftSkill = true
          if (this.emtionsArray && this.emtionsArray.length) {
            this.$nextTick(() => {
              // this.drawCharts(this.emtionsArray)
            })
          }
        }
      },
    },
    // l2ReportInfo: {
    //   immediate: true,
    //   deep: true,
    //   handler() {
    //     const arr = (this.l2ReportInfo?.answerInfos || []).map(item => {
    //       return { ...item, visible: false }
    //     })
    //     this.answerInfos = arr.sort((a, b) => {
    //       return a?.sortIndex - b?.sortIndex
    //     })
    //   },
    // },
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.initScoreRadar()
    // })
  },

  methods: {
    initScoreRadar() {
      // this.detailVisible = !this.detailVisible
      if (this.seriesData && this.seriesData.length && this.seriesData.length > 2) {
        const chartDom = document.getElementById('scoreRadar')
        if (chartDom) {
          const myChart = echarts.init(chartDom)
          const option = {
            radar: this.radar,
            series: this.series,
          }
          option && myChart.setOption(option)
        }
      }
    },
    drawCharts(valueArray) {
      console.log(valueArray)
      const myChart = echarts.init(document.getElementById('emotion-eCharts'))
      const option = {
        color: valueArray.map(item => {
          return item.color
        }),
        title: {
          text: `AI${this.isYJLCompany ? '-' : ''}${this.BQ}\n${i18n.t('com.report.reportV3.text038')}`,
          top: '30%',
          left: 'center',
          textStyle: {
            lineHeight: 30,
            color: '#AFB6CA',
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}:{d}%',
        },
        legend: {
          show: true,
          orient: 'horizontal',
          bottom: 0,
          formatter: function(name) {
            let total = 0
            let target = 0
            for (let i = 0, l = valueArray.length; i < l; i++) {
              total += valueArray[i].value
              if (valueArray[i].name === name) {
                target = valueArray[i].value
              }
            }
            // const arr = ['{a|' + name + ' ' + ((target / total) * 100).toFixed(2) + '%}']
            // return arr.join('\n')
            return name + ' ' + ((target / total) * 100).toFixed(2) + '%'
          },
          // textStyle: {
          //   rich: {
          //     a: {
          //       width: 60,
          //     },
          //   },
          // },
          data: valueArray.map(item => {
            return item.name
          }),
        },
        series: [
          {
            // name: '访问来源',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '40%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              formatter: '{b|{b}：}{c}  {per|{d}%}  ',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '30',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: valueArray,
          },
        ],
      }
      myChart.setOption(option)
    },
    isPositiveInteger(value) {
      // null > 0
      return value >= 0
    },
  },
}
</script>
<style lang="scss">
.soft__skills {
  // width: calc(100% - 16px);
  margin: 16px auto 0;
  .l2-question {
    margin: 20px auto 0 auto;
    .l2-q_t {
      margin-bottom: 20px;
    }

    .l2-q_r {
      height: 260px;
      width: 360px;
      margin: auto;
      margin-bottom: 20px;
    }
  }
  .score-wrapper {
    margin: 0px auto 0 auto;
    padding: 0px 0px 10px 0px;

    .desc {
      margin-top: 6px;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }

    .info {
      margin-top: 4px;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
    }

    .process-wrapper {
      margin-top: 8px;
    }
    .score-explain {
      margin-top: 8px;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
  }
  .line1 {
    // padding-left: 20px;
    // margin-top: 55px;
    font-weight: 600;
    font-size: 16px;
  }

  .ai__score__title {
    margin-top: 12px;
    padding: 0 25px;
    font-size: 14px;
    color: #333333;
    font-weight: 600;
  }
  .ai__score__desc {
    font-size: 14px;
    // padding: 0 25px;
    color: #333333;
  }

  .estimate-group {
    margin-top: 10px;
    .estimate-score-wrapper {
      margin: 10px auto;
      height: 60px;
      background: #ffffff;
      box-shadow: 2px 2px 10px 0px rgba(169, 193, 205, 0.34);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 25px;
      .estimate-title {
        width: 116px;
        // height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
      }
      /deep/ .el-rate {
        .el-rate__icon {
          font-size: 22px;
          margin-right: 0px;
        }
      }
    }
  }

  .eCharts-content {
    width: 90%;
    margin: 0 auto;
    background-color: #fafafa;
    #emotion-eCharts {
      box-sizing: border-box;
      background-color: #fafafa;
      width: 100%;
      margin: 1rem auto 0 auto;
      height: 350px;
    }
  }
  .emition-total {
    width: 100%;
    display: flex;
    height: 20px;
    margin-top: 10px;
    margin-bottom: 8px;
    .emition-item {
      cursor: pointer;
      transition: all 0.2s ease-out;
    }
  }
  .emition-dots {
    display: flex;
    flex-wrap: wrap;
    .dot-item {
      width: 33%;
      display: flex;
      align-items: center;
      line-height: 1.8;

      .dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }

  .top-emotion-analysis {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
  }

  .emotion-row {
    width: 100%;
    // padding: 0 25px;
    margin-top: 8px;
    .flexd {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }
    .dot {
      width: 8px;
      height: 8px;
      flex-shrink: 0;
      background: #afb6ca;
      border-radius: 50%;
    }
    .emotion-text {
      margin-left: 4px;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
    .emotion-text-desc {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
    }
  }
}
.mode-img {
  img {
    height: 24px;
  }
}
</style>
