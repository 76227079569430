export default {
  text001: 'AI打分',
  text002: '基于企业岗位的胜任力要求，利用AI技术对候选人的核心潜力（AI Talent DNA)、{bq}、{sy}等进行多模态分析得出的综合评价',
  text003: 'L4-AI视频总分: ',
  text004: '生成中',
  text005: '模型训练中',
  text006: 'L2-AI视频总分',
  text007: '视频人工总分',
  text008: '篇章语义分析得分',
  text009: '基于篇章级别的自然语言算法对候选人回答问题的质量的综合判断得分。',
  text010: '{label}-AI总分排名占整体候选人情况',
  text011: '分析得分',
  text012: '基于人工智能的人脸识别算法对候选人的五官和轮廓的综合判断打分。',
  text013: '基于自主研发领先人工智能语音算法对候选人回答面试问题时的音量、音调等五大声音维度进行综合分析，从而判断候选人在语音表达上的水平。',
  text014: '基于宏表情分析的计算机视觉算法对候选人情绪和回答问题真实性的综合判断得分。',
  text015: '以下为该候选人主要表情（TOP 3 表情分析）',

  text016: '该候选人在面试过程中没能展现出自己具备胜任该岗位的能力，因此在未来的工作中TA可能无法完成上级分配的工作任务。',
  text017: '该候选人可能只满足了少数胜任力维度上的最低要求，因此在未来的工作中TA可能很难完成上级所分配的任务。',
  text018: '该候选人在面试过程中证明了自己具备完成工作所需的基本胜任力水平，因此TA应该具备完成任务，达成基本目标的能力。',
  text019: '该候选人在面试过程中展现出了良好的胜任力水平，因此TA应该具备超越绩效标准的能力。',
  text020: '该候选人在面试过程中展现出了优秀的胜任力水平，因此在未来的工作中TA应该能够出色地完成工作任务。',

  text021: '表情',
  text022: '声音',
  text023: '职业形象',

  text024: '该候选人在面试过程中表现得非常紧张，不适，大部分时间都展现出害怕，经验，讨厌等消极表情。',
  text025: '该候选人在面试过程中表现得较为紧张，不适。多次出现难过，惊讶，讨厌等消极表情。',
  text026: '该候选人在面试过程中表现平稳，没有明显的情绪起伏和变化。',
  text027: '该候选人在面试过程中变现的较为轻松，自如，自信，常常面带微笑。',
  text028: '该候选人在面试过程中表现得非常轻松，愉悦，自信，始终面带笑容。',

  text029: '该候选人在面试过程中可能频繁出现：声音卡顿，语速过快或过慢，毫无节奏等问题，使得听众感到明显的不适。',
  text030: '候选人在面试过程中可能多次出现：声音卡顿，语速过快或过慢，毫无节奏等问题、引起听众的不适。',
  text031: '面试过程中，该候选人声音的整体表现一般，可能存在一些卡顿，语速不自然，说话缺乏节奏感等问题。',
  text032: '面试过程中，该候选人声音的整体表现良好，语言流畅，语速自然，但可能存在少许瑕疵（如：缺乏节奏感，少量卡顿等）。',
  text033: '面试过程中，该候选人声音的整体表现表现优异，语言流畅，语速自然，声音抑扬顿挫，婉转动听，令人沉醉。',

  text034: '难以接受，可能带来非常强烈的视觉冲击，诱发消极的情绪体验。',
  text035: '该候选人职业形象能够引起轻微的不适感。',
  text036: '大众职业形象，看到之后不会出现明显的情绪体验和波动。',
  text037: '该候选人职业形象带来的感受高于平均水平，看起来比较舒服。',
  text038: '明星级别的职业形象，看到之后能够带来明显的愉悦感。',

  text039: '分析详情',
  text040: '报告总结',
  text041: '口音总分-中文',
  text042: '口音总分-英文',
  text043: '综合评价',
  text044: '视频人工总分',
  text045: '{0}次',
  text046: '违规操作',
  text047: '答题用时',
  text048: '初次进入时间',
  text049: '完成面试时间',
  text050: '总分',
  text051: '需要注意的事项',
  text052:
    '本报告为自动生成，使用者能够使用软件对报告⽂本进行修改。本测验的结果可以为企业招聘、选拔、培养管理类人才提供参考，但不能仅仅根据其中的某一份报告做出是否录用的决定。企业可以将系统中的认知能力测试，职业性格测试，岗位专业能力测试与面试等人才素质测评方法配合使用，综合评价候选人的素质能力，才能更精准地识别和岗位匹配度较高的优质候选人，从而提升招聘效能本报告结果注意保密，只供公司领导、⼈力资源部相关负责人或测评者本⼈阅读。在解读各测试分数时，应该结合招聘岗位的具体工作职责，以此来判定这些维度属于个人优势还是需要改进。想要了解如何根据各测试分数做出更为明智的招聘决定，请联系近屿智能专业咨询顾问，电话：400-0963-520。与所有人才测评工具一样，本报告包含的所有测试结果并非绝对正确无误，任何基于本报告所做出的用人决策，即使该决策带给企业损失，上海近屿智能科技有限公司对此不承担任何法律责任。取决于个⼈情况，本报告很可能在出具的 12 个⽉内都能够⼀直有效地反映个体的潜能。',
  text053: '版权声明',
  text054:
    ' 本报告包含的所有内容（包括但不限于文本、数据、图片、图标、logo等）都受到商标法、知识产权法的保护，归上海近屿智能科技有限公司所有。未经上海近屿智能科技有限公司书面许可，任何人不得使用、修改、复制、翻译、公开及出售任何与本报告有关的内容及形式。',

  text055: '口音分析',
  text056: '口音总分',
  text057: '口音流利度',
  text058: '口音准确度',
  text059: '{0}星',

  text060: 'L2单题打分',
  text061: '本题得分',
  text062: '收起',
  text063: '详情',
  text064: '本题权重',
  text065: '考核点',
  text066: 'L2-普通话',
  text067: 'L2-英语',
  text068: '测试候选人普通话水平能力，以中文识别候选人回答内容。',
  text069: '测试候选人英语水平能力，以英文识别候选人回答内容。',

  text070: 'L2-是否意愿',
  text071: '用于判断候选人对面试内容的接受意愿，如“是否熟练使用手机”。',
  text072: 'L2-关键词命中',
  text073: 'AI将分析候选人回答内容是否含所需关键词要点，语意相关度匹配。',
  text074: 'L2-语速',
  text075: '考核候选人说话语速正常程度，是否过快过慢。',
  text076: 'L2-语词丰富',
  text077: '候选人描述事物的词汇丰富程度，以及详细程度。',
  text078: 'L2-语意流畅',
  text079: '候选人表达事物的连贯性以及流利程度。',
  text080: 'L2-职业形象',
  text081: '候选人是否五官端正，符合通常普遍审美。',
  text082: 'L2-外貌年龄',
  text083: '识别候选人外观年龄，长相是否年轻。',
  text084: 'L2-保持微笑',
  text085: '考核候选人在面试过程中是否保持微笑。',
  text086: '差',
  text087: '较差',
  text088: '一般',
  text089: '良好',
  text090: '优秀',
  text091: '报告说明',
  text092: '测评总览',
  text093: '候选人胜任力综合得分',
  text094: '情绪稳定性得分',
  text095: '评估有效性',
  text096: '答题用时',
  text097: '初次进入时间',
  text098: '完成面试时间',
  text099: '胜任力报告维度',
  text100: '情绪稳定性报告维度',
  text101: '没有内容可以查看',
  text102: '分',
  text103: '在线考试',
  text104: '该模块涵盖了候选人在以下所有题目类别上的答题情况，包括：单选题，多选题，判断题，排序题，论述题，口音题。',
  text105: '选择题，判断题，排序题的分数只有在设定好题目分数以及标准答案后，才会涵盖在图表的总分当中。',
  text106: '论述题的分数只有在设定好题目分数以及面试官评价过后，才会涵盖在图表的总分当中。',
  text107: '备注',
  text108: '得分',
  text109: '答题情况',
  text110: '正确题数',
  text111: '错误题数',
  text130: 'L5-AI视频总分: ',
  text131: '(AI追问)',
  text132: '本题不计分',
  text133: '回答正确',
  text134: '回答不全',
  text135: '回答错误',
  text136: '正确',
  text137: '错误',
  text138: '未设置',
  text139: '已击败',
  text140: '的候选人',
  text141: '口音题总分(中文)：',
  text142: '口音题总分(英文)：',
  text143: '{0}分',
  text144: '视频记录',
  text145: 'AI维度分析',
  text146: '胜任力维度得分解读',
  text147: '归纳总结：',
  text148: '下一轮重点考察建议：',
  text149: '胜任力定义：',
  text150: '胜任力评分标准：',
  text151: '候选人综合分析：',
  text152: '*如需详细了解各胜任力具体得分、评分依据及进一步考察建议，请继续查阅各个胜任力的单独评估报告。',
  text153: '评分分析：',
  text154: '星',
  LevelOfCompetency: '胜任力素质\n星级',
  yxexsx: '1星：完全不胜任 \xa0\xa0\xa0 2星：不胜任 \xa0\xa0\xa0 3星：胜任 \xa0\xa0\xa0 4星：优秀 \xa0\xa0\xa0 5星：超出期望',
  text_aigd: 'AI面试等级说明',
  text_l2: '以关键词识别为代表的初级AI技术，信效度较低，不建议作为候选人面试是否通过判断的依据，请谨慎参考。',
  text_l3:
    '基于篇章级别语义识别算法，深度辅助人类面试官对候选人的胜任力进行判断，请关注L3级别的分数不考虑候选人的表情、声音等项目的表现。',
  text_l4:
    '基于近屿智能自研的AI多模态算法，对候选人的篇章级别语义、表情和声音进行综合判断，判断准确率可以通过双盲人机对比实验。',
  text_l5:
    '基于近屿智能自研的AIGC多模态大模型，AI面试官可以做到多轮对话、千人千问、实时追问，对候选人的篇章级别语义、表情和声音进行综合判断，判断准确率可以通过双盲人机对比实验。',
}
