import Vue from 'vue'
import Vuex from 'vuex'
import { queryInterviewPlan } from '@/api/report'
import { getAuthInfo } from '@/api/open'
import { searchRecommendResume, getEvaluationConfig } from '@/api/candidate'
import { getUserInfo } from '@/api/admin'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const sysLangs = ['cn', 'en', 'zh-TW']

console.log('sysLangs', sysLangs, navigator)
const navigatorLangs = navigator.languages.map(lang => {
  return ['zh', 'zh-cn'].includes(lang.toLocaleLowerCase()) ? 'cn' : lang
})
const preferLang = navigatorLangs.find(lang => sysLangs.includes(lang)) || 'cn'

console.log(preferLang)

export default new Vuex.Store({
  state: {
    lang: sessionStorage.getItem('lang') || preferLang,
    previewResumeUrl: '',
    positionId: '',
    companyId: '',
    interviewId: '',
    token: localStorage.getItem('token'),
    jobseekerChannelId: '',
    corpId: '',
    estimateType: 0,
    //当前操作用户
    currentUserId: '',
    noWifi: false,
    userInfo: null,
    appId: process.env.NODE_ENV === 'production' ? 4 : 3,
    education: null,
    workInfo: null,
    projectInfo: null,
    //是否请求过教育经历，工作经历，项目经历这3个接口
    requestiInterviewDataFlag: false,
    //候选人的基本信息
    interviewResult: {},
    //多次作答记录
    interviewInfos: [],
    // 候选人interviewMsg
    interviewMsg: {},
    //候选人答的题目
    answerResult: null,
    //候选人面试安排
    interviewArrangeList: null,
    //ai各项的分
    aiData: null,
    showManualEstimateDialog: false,

    interviewPlanList: null,
    //是否是微信环境
    goWechat: false,
    waitEstimate: false,
    redirectUrl: '',
    interviewPlanId: '',
    //候选人简历筛选的列表
    shortList: [],
    routeLength: 0,
    haveOperateCount: 0,
    noOperateCount: 0,
    invalidCount: 0,
    remarkList: [],
    allEstmate: [],
    isShowAiVideo: '',
    yjlCompanyId: [
      // 雅居乐公司id
      'f57ee78fba7e48e6b3534984e79553eb', //正式
      '2565243e57df433da761e20a57fe030c',
      'f57ee78fba7e48e6b3534984e79553eb',
    ],
    zjCompanyId: [
      '85c8c7a589b4490f918243790d5f02f4', //正式区
      'df42ccecb2bb4c53890cb284fff27ace', //mini
    ],
    jdCompanyId: ['cbbd886a558849abaa6c577b437cbd42'],
    xmzCompanyId: ['d85e22d86f21480189ebb4457aa20566'],
    hytCompanyId: [
      '281c0f576c7c4ac7ab6ea01f7d6cd424', //正式 18566655740
      '49d1488380ff4b8e9fb7ff88f961001c', //mini 18566655740
    ],
    // 安永
    anyongCompanyId: [
      // 'a3d7e2def4654f64b2c59717152002a3', // mini
      '029dc1855de246f082fe407b3888eb69', // hr
    ],
    // 携程
    xiechengCompanyId: [
      '7f8af9ebb64e48c3bc594524f809df9c', // uat
      'f93a4cbf70104adc932ec296d29c7a00', // uat
      '8685c1b6e0c84b29884ca908829fccab', // 正式  
    ],
    tclEnPostionIds: ['ca946b585f3c415e9122535d125c165d', '0ff44fd2bf8845369b8ca385e1551267', '4b25a07a8af4407780b8a5d1b8c9bde1'],
    fiveYearsVisbile: true,
    evaluationConfig: {},
    aiMarkCfg: {
      allStatus: true,
      singleStatus: true,
      manualStatus: true,
      aiScoreStatus: true,
    },
    aiReportCfg: [],
    reportTabConfig:[]
  },
  mutations: {
    setFiveYearsVisbile(state, fiveYearsVisbile) {
      state.fiveYearsVisbile = fiveYearsVisbile
    },
    SET_HYTCOMPANYID(state, hytCompanyId) {
      state.hytCompanyId = hytCompanyId
    },
    setRemarkList(state, remarkList) {
      state.remarkList = remarkList
    },
    setAllEstmate(state, allEstmate) {
      state.allEstmate = allEstmate
    },
    setRouteLength(state, routeLength) {
      state.routeLength = routeLength
    },
    setHaveOperateCount(state, haveOperateCount) {
      state.haveOperateCount = haveOperateCount
    },
    setNoOperateCount(state, noOperateCount) {
      state.noOperateCount = noOperateCount
    },
    setInvalidCount(state, noOperateCount) {
      state.invalidCount = noOperateCount
    },
    setShortList(state, shortList) {
      state.shortList = shortList
    },
    setInterviewPlanId(state, interviewPlanId) {
      state.interviewPlanId = interviewPlanId
    },
    setRedirectUrl(state, redirectUrl) {
      state.redirectUrl = redirectUrl
    },
    setGoWechat(state, goWechat) {
      state.goWechat = goWechat
    },
    setInterviewPlanList(state, interviewPlanList) {
      state.interviewPlanList = interviewPlanList
    },
    setShowManualEstimateDialog(state, showManualEstimateDialog) {
      if (showManualEstimateDialog) {
        var scrollTop = document.body.scrollTop || document.documentElement.scrollTop
        document.body.style.cssText += 'position:fixed;top:-' + scrollTop + 'px;'
      }
      state.showManualEstimateDialog = showManualEstimateDialog
      if (!showManualEstimateDialog) {
        var body = document.body
        body.style.position = 'static'
        var top = body.style.top
        document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top)
        body.style.top = ''
      }
    },
    setAIData(state, aiData) {
      state.aiData = aiData
    },
    setPreviewResumeUrl(state, previewResumeUrl) {
      state.previewResumeUrl = previewResumeUrl
    },
    setCompanyId(state, companyId) {
      state.companyId = companyId
    },
    setInterviewArrangeList(state, interviewArrangeList) {
      state.interviewArrangeList = interviewArrangeList
    },
    setPositionId(state, positionId) {
      state.positionId = positionId
    },
    setJobseekerChannelId(state, jobseekerChannelId) {
      state.jobseekerChannelId = jobseekerChannelId
    },
    setToken(state, token) {
      state.token = token
    },
    setInterviewId(state, interviewId) {
      state.interviewId = interviewId
    },
    setAnswerResult(state, answerResult) {
      state.answerResult = answerResult
    },
    setInterviewResult(state, interviewResult) {
      state.interviewResult = interviewResult
    },
    setInterviewInfos(state, interviewInfos) {
      state.interviewInfos = interviewInfos
    },
    setInterviewMsg(state, interviewMsg) {
      state.interviewMsg = interviewMsg
    },

    setWorkInfo(state, workInfo) {
      state.workInfo = workInfo
    },
    setProjectInfo(state, projectInfo) {
      state.projectInfo = projectInfo
    },
    setEducation(state, education) {
      state.education = education
    },
    setRequestiInterviewDataFlag(state, requestiInterviewDataFlag) {
      state.requestiInterviewDataFlag = requestiInterviewDataFlag
    },
    setCorpId(state, corpId) {
      state.corpId = corpId
    },
    setEstimateType(state, estimateType) {
      state.estimateType = estimateType
    },
    setCurrentUserId(state, currentUserId) {
      state.currentUserId = currentUserId
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setShowAiVideo(state, ShowAiVideo) {
      state.isShowAiVideo = ShowAiVideo
    },
    setNoWifi(state, noWifi) {
      state.noWifi = noWifi
    },
    setAppId(state, appId) {
      state.appId = appId
    },
    clearData(state) {
      state.previewResumeUrl = ''
      state.positionId = ''
      state.companyId = ''
      state.interviewId = ''
      state.jobseekerChannelId = ''
      state.estimateType = 0
      state.education = null
      state.workInfo = null
      state.projectInfo = null
      //是否请求过教育经历，工作经历，项目经历这3个接口
      state.requestiInterviewDataFlag = false
      //候选人的基本信息
      state.interviewResult = {}
      //候选人答的题目
      state.answerResult = null
      //候选人面试安排
      state.interviewArrangeList = null
      //ai各项的分
      state.aiData = null
      state.showManualEstimateDialog = false
      state.interviewPlanList = null
      state.interviewPlanId = ''
      state.interviewInfos = []
    },
    SET_EVALUATION_CONFIG: (state, config) => {
      state.evaluationConfig = config
    },
    setLanguage(state, lang) {
      state.lang = lang
      sessionStorage.setItem('lang', lang)
    },
    SET_AICFG: (state, payload) => {
      console.log('设置AI标注状态-------------', payload)
      state.aiMarkCfg = payload
    },
    SET_AiReportCfg: (state, payload) => {
      state.aiReportCfg = payload
    },
    SET_ReportTabConfig: (state, payload) => {
      state.reportTabConfig = payload
    },
  },
  actions: {
    queryInterviewPlan: ({ commit, state }) => {
      queryInterviewPlan({ positionId: state.positionId, jobseekerChannelId: state.jobseekerChannelId }).then(res => {
        if (res.result && res.code === 0) {
          let list = Array.isArray(res.result) ? res.result : []
          commit('setInterviewPlanList', list)
        }
      })
    },
    getAuthInfo: ({ commit, state }) => {
      getAuthInfo({ openId: state.openId }).then(res => {
        if (res && res.code === 0) {
          commit('setUserInfo', res.data)
        }
      })
    },
    getUserInfo: ({ commit }) => {
      getUserInfo().then(res => {
        if (res && res.code === 0) {
          commit('setUserInfo', { ...res.data, token: localStorage.token })
        }
      })
    },
    requestShortList: ({ commit }) => {
      searchRecommendResume({
        email: '',
        maxWorkAge: '',
        minWorkAge: '',
        name: '',
        phone: '',
        positionId: '',
        recommendStatus: '',
        haveOperate: 0,
      }).then(res => {
        if (res.code === 0) {
          commit('setShortList', res.data.result || [])
          commit('setHaveOperateCount', res.data.haveOperateCount)
          commit('setNoOperateCount', res.data.noOperateCount)
          commit('setInvalidCount', res.data.invalidCount || 0)
        }
      })
    },
    getEvaluationConfig: async ({ commit }) => {
      await getEvaluationConfig().then(res => {
        if (res && res.code === 0) {
          commit('SET_EVALUATION_CONFIG', res.data || {})
        }
        return res
      })
    },
  },
  modules: {},
  plugins: [createPersistedState({ key: 'vuexMobileStore', paths: ['token', 'userInfo', 'lang'] })],
})
