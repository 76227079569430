export default {
  text001: '上一位',
  text002: '下一位',
  text003: '已篩選',
  text004: '待篩選',
  text005: '已失效',
  text006: '返回',
  text007: '簡歷資訊',
  text008: 'AI視頻面試',
  text009: '線上筆試',
  text010: '認知能力',
  text011: '職業性格',
  text012: 'Meritas-心理健康',
  text013: '代碼考試',
  text014: 'PDP測試報告',
  text015: '第三方測評',
  text016: '線下麵試結果',
  text017: '面試資訊',
  text018: '備註',
  text019: '附加資訊',
  text020: '該候選人共面試了{count}次',
  text021: 'AI視頻記錄',
  text022: 'AI報告',
  text023: '筆試報告',
  text0231: '閱讀題',
  text024: '論述題',
  text025: '客觀題',
  text026: '選擇語言',
  text027: '{0}歲',
  text028: 'L4-總分',
  text029: '生成中',
  text030: '模型訓練中',
  text031: 'L2-總分',
  text032: '視頻人工總分',
  text033: '考核得分',
  text034: '{0}分',
  text035: 'L2-AI表情',
  text036: 'L2-AI職業形象',
  text037: 'L2-AI聲音',
  text038: '篇章語義',
  text039: '候選人尚未被邀請參加AI面試',
  text040: '候選人尚未完成AI面試',
  text041: '簡歷解析中',
  text042: '您無權限查看',
  text043: '編程題',
  text044: '轉發',
  text045: '評價記錄',
  text046: '通過',
  text047: '已通過',
  text048: '待定',
  text049: '已待定',
  text050: '拒絕',
  text051: '已拒絕',
  text052: '拒絕面試',
  text053: '接受面試',
  text054: '請輸入郵箱',
  text055: '郵箱',
  text056: '確認',
  text057: '您已通過簡歷',
  text058: '是否立即設置面試時間',
  text059: '不再提示',
  text060: '暫不設置',
  text061: '設置時間',
  text062: '簡體中文',
  text063: '繁體中文',
  text064: '已經是第一位候選人了',
  text065: '已經是最後一位候選人了',
  text066: '原始簡歷',
  text067: '標準簡歷',
  text068: '應聘登記表',
  text069: '操作成功',
  text070: '操作失敗',
  text071: '鏈接已失效',
  text072: '轉發成功',
  text073: '分享成功',
  text074: '郵箱格式不正確',
  text075: '候選人暫無面試視頻可查閱',
  text076: '候選人尚未完成AI測評',
  text077: '報告總結',
  text078: '視頻面試',
  text079: '自定義試題',
  text080: '模擬編程',
  text081: '男',
  text082: '女',
  text083: '未知',
  text086: 'L5-視頻AI總分',
  text087: 'L2-AI總分',
  text088: '防作弊記錄',
  text090: '英文筆試總分',
  meritas: 'MeriTas測評',
  wqbsr: '完全不勝任',
  bushengren: '不勝任',
  shengren: '勝任',
  chaochuqiwang: '超出期望',
  youxiu: '優秀',
  text100: '綜合總分',
  text100_1: '粵語能力',
  text100_2: '英語能力',

}
