<template>
  <div class="report-content" ref="reportContent">
    <div class="up-and-down-wrapper" v-if="shortList && shortList.length && routeLength > 1 && !imgLoading" v-drag="{ right: '18px', bottom: '68px' }">
      <!-- <div class="up-and-down-wrapper" v-if="shortList && shortList.length && !imgLoading" v-drag="{ right: '18px', bottom: '68px' }"> -->
      <div class="up" @click="upCandidate">
        <div class="text-wrapper">{{ $t('com.report.base.text001') }}</div>
      </div>
      <div class="center"></div>
      <div class="down" @click="downCandidate">
        <div class="text-wrapper">{{ $t('com.report.base.text002') }}</div>
      </div>
    </div>

    <!--简历筛选状态下显示已筛选和未筛选的人数-->
    <!-- <div class="screen-tips-container" :class="{ 'screen-tips-container-flod': screenTipsContainerFlod }" v-if="shortList && shortList.length && routeLength > 1 && routeFrom === 'recommend'"> -->
    <div class="screen-tips-container" :class="{ 'screen-tips-container-flod': screenTipsContainerFlod }" v-if="recommendStageId">
      <div class="screen-left" @click="screenTipsContainerFlod = !screenTipsContainerFlod">
        <img src="@/assets/img/double-arrow-left-icon.png" v-show="screenTipsContainerFlod" />
        <img src="@/assets/img/double-arrow-right-icon.png" v-show="!screenTipsContainerFlod" />
      </div>
      <div class="screen-right">
        <div class="screen-line-up">{{ $t('com.report.base.text003') }}:{{ haveOperateCount }}</div>
        <div class="screen-line-down">{{ $t('com.report.base.text004') }}:{{ noOperateCount }}</div>
        <div class="screen-line-down">{{ $t('com.report.base.text005') }}:{{ invalidCount }}</div>
      </div>
    </div>
    <div class="top-row" v-if="canBack">
      <van-icon name="arrow-left" @click="goBack" /><span @click="goBack">{{ $t('com.report.base.text006') }}</span>
    </div>
    <div class="tabs-list tabfixed" ref="tabBar" v-show="scrollTop > baseInfoHeight + tabBarHeight && !isDwdTest">
      <ul class="tabs">
        <li :class="{ active: tabIndex === 0 }" @click="updateTabIndex(0)" v-if="permissionsTreeId.includes('reportResume')">
          <div>{{ $t('com.report.base.text007') }}</div>
        </li>
        <li
          :class="{ active: tabIndex === 1 }"
          @click="updateTabIndex(1)"
          v-if="
            permissionsTreeId.includes('reportAIInterview') &&
            (showAllAiTab || (!showAllAiTab && currentPerson && answerResult && answerResult.length && hasVideoQues && hasVideoQues.length)) &&
            interviewResult.haveAi === 1
          "
        >
          <div>{{ $t('com.report.base.text008') }}</div>
        </li>
        <!-- <li :class="{ active: tabIndex === 2 }" @click="updateTabIndex(2)" v-if="interviewResult.haveAi === 1">
					<div>AI报告</div>
				</li> -->

        <li
          :class="{ active: tabIndex === 17 }"
          @click="updateTabIndex(17)"
          v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && zxbsHasData)) && interviewResult.haveAi === 1"
        >
          <div>{{ $t('com.report.base.text009') }}</div>
        </li>
        <li
          :class="{ active: tabIndex === 18 }"
          @click="updateTabIndex(18)"
          v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && rznlHasData)) && interviewResult.haveAi === 1"
        >
          <div>{{ $t('com.report.base.text010') }}</div>
        </li>
        <li
          :class="{ active: tabIndex === 19 }"
          @click="updateTabIndex(19)"
          v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && zyxgHasData)) && interviewResult.haveAi === 1"
        >
          <div>{{ $t('com.report.base.text011') }}</div>
        </li>
        <li
          :class="{ active: tabIndex === 21 }"
          @click="updateTabIndex(21)"
          v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && meritasHasData)) && interviewResult.haveAi === 1 && meritasShow"
        >
          <div>{{ $t('com.report.base.meritas') }}</div>
        </li>
        <li
          :class="{ active: tabIndex === 20 }"
          @click="updateTabIndex(20)"
          v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && xljkHasData)) && interviewResult.haveAi === 1"
        >
          <div>{{ $t('com.report.base.text012') }}</div>
        </li>
        <li
          :class="{ active: tabIndex === 16 }"
          @click="updateTabIndex(16)"
          v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && dmksHasData)) && interviewResult.haveAi === 1"
        >
          <div>{{ $t('com.report.base.text013') }}</div>
        </li>
        <li v-if="isZj" :class="{ active: tabIndex === 12 }" @click="updateTabIndex(12)">
          <div>{{ $t('com.report.base.text014') }}</div>
        </li>
        <li v-if="permissionsTreeId.includes('thirdComment') && !isZj" :class="{ active: tabIndex === 5 }" @click="updateTabIndex(5)">
          <div>{{ $t('com.report.base.text015') }}</div>
        </li>

        <li v-if="isZsCompany" :class="{ active: tabIndex === 11 }" @click="updateTabIndex(11)">
          <div>{{ $t('com.report.base.text016') }}</div>
        </li>
        <li :class="{ active: tabIndex === 3 }" @click="updateTabIndex(3)" v-if="permissionsTreeId.includes('planInterview')">
          <div>{{ $t('com.report.base.text017') }}</div>
        </li>
        <li :class="{ active: tabIndex === 4 }" @click="updateTabIndex(4)" v-if="permissionsTreeId.includes('reportRemark')">
          <div :id="remarkList && remarkList.length > 0 ? 'mark_label_1' : ''" :data-num="remarkList && remarkList.length > 0 ? '(' + remarkList.length + ')' : ''">
            {{ $t('com.report.base.text018') }}
          </div>
        </li>
        <li :class="{ active: tabIndex === 6 }" @click="updateTabIndex(6)" v-if="showAllAiTab || (!showAllAiTab && attachInfo && attachInfo.length)">
          <div>{{ $t('com.report.base.text019') }}</div>
        </li>
        <li :class="{ active: tabIndex === 22 }" @click="updateTabIndex(22)" v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && fzbHasData))">
          <div>{{ $t('com.report.base.text088') }}</div>
        </li>
      </ul>

      <ul class="small-tabs" v-if="tabIndex === 0 && resumeTab && resumeTab.length > 1 && !isAnonymous">
        <li v-for="(tabItem, tabIndex) in resumeTab" :key="tabIndex" :class="{ active: activeIndex === tabIndex }" @click="updatedActiveIndex(tabIndex, tabItem)">{{ tabItem.text }}</li>
        <div class="tabs__line" :style="{ transform: 'translateX(' + (100 / resumeTab.length / 4 + (100 / resumeTab.length) * activeIndex) + 'vw )', width: 100 / resumeTab.length / 2 + 'vw' }"></div>
        <div class="tabs__line" v-if="resumeTab && resumeTab.length === 2" :style="{ transform: 'translateX(calc(' + (25 + 50 * activeIndex) + 'vw - 1rem))' }"></div>
      </ul>
      <div class="repeat-interview" v-if="tabIndex === 1 && interviewInfos && interviewInfos.length && interviewInfos.length > 1" @click="handleClick">
        <div class="re-left">
          <!--  该候选人共面试了次 -->
          <i18n path="com.report.base.text020" tag="span">
            <span place="count" style="color: #1890ff"> {{ interviewInfos.length }} </span>
          </i18n>
        </div>
        <div>{{ currentInterviewInfo.interviewName }} <van-icon name="arrow-down" /></div>
      </div>
      <ul class="small-tabs disnone" v-if="tabIndex === 1">
        <li :class="{ active: activeIndex === 0 }" @click="updatedActiveIndex(0)">{{ $t('com.report.base.text021') }}</li>
        <li :class="{ active: activeIndex === 1 }" @click="updatedActiveIndex(1)">{{ $t('com.report.base.text022') }}</li>

        <!-- <li :class="{ active: activeIndex === 2 }" @click="updatedActiveIndex(2)">在线考试记录</li> -->
        <div class="tabs__line" :style="{ transform: 'translateX(' + (100 / 2 / 4 + (100 / 2) * activeIndex) + 'vw )', width: 100 / 2 / 2 + 'vw' }"></div>
      </ul>
      <ul class="small-tabs" v-if="tabIndex === 17">
        <li :class="{ active: activeIndex === 0 }" @click="updatedActiveIndex(0)">{{ $t('com.report.base.text023') }}</li>
        <li :class="{ active: activeIndex === 1 }" @click="updatedActiveIndex(1)">{{ $t('com.report.base.text024') }}</li>
        <li :class="{ active: activeIndex === 2 }" @click="updatedActiveIndex(2)">{{ $t('com.report.base.text025') }}</li>
        <li :class="{ active: activeIndex === 3 }" @click="updatedActiveIndex(3)">{{ $t('com.report.base.text0231') }}</li>
        <div class="tabs__line" :style="{ transform: 'translateX(' + (100 / 4 / 4 + (100 / 4) * activeIndex) + 'vw )', width: 100 / 4 / 2 + 'vw' }"></div>
      </ul>
    </div>
    <div class="base-info" v-show="!isDwdTest" ref="baseInfo">
      <div class="base-info_base">
        <div class="change-lanuage">
          <div @click="showLangChange = true">CN/EN</div>
          <van-action-sheet v-model="showLangChange" :title="$t('com.report.base.text026')" :actions="languageActions" @select="onSelectLang" />
        </div>
        <div class="avatar-wrapper">
          <img class="avatar" :src="avatarUrl" alt="picture" />

          <a v-if="interviewResult.phone && !isAnonymous" @click="call(interviewResult.phone)">
            <img class="phone" src="~@/assets/base-info/phone.png" />
          </a>
        </div>
        <div class="base_name" v-if="interviewResult.name">{{ isAnonymous ? '**' : interviewResult.name }}</div>
        <div class="base_job" v-if="interviewResult.position">{{ interviewResult.position }}</div>
        <div class="base_detail">
          <div class="base_detail_item" v-if="interviewResult.gender !== null || interviewResult.age">
            <img src="~@/assets/base-info/age.png" />
            <div class="content">
              <span v-if="interviewResult.gender !== null"> {{ interviewResult.gender | filterGender }}</span>
              <span v-if="interviewResult.gender !== null && interviewResult.age"> · </span>
              <span v-if="interviewResult.age" class="text">{{ $t('com.report.base.text027', [interviewResult.age]) }}</span>
            </div>
          </div>

          <div class="base_detail_item" v-if="interviewResult.education">
            <img src="~@/assets/base-info/education.png" />
            <div class="content">
              <span>{{ interviewResult.education | F_education }}</span>
            </div>
          </div>

          <div class="base_detail_item" v-if="interviewResult.native_place">
            <img src="~@/assets/base-info/location.png" />
            <div class="content">
              <span>{{ interviewResult.native_place }}</span>
            </div>
          </div>

          <div class="base_detail_item" v-if="interviewResult.referee">
            <img src="~@/assets/base-info/referee.png" />
            <div class="content">{{ interviewResult.referee }}</div>
          </div>
        </div>
      </div>
      <div class="base-info_ai" v-if="estimateType === 2">
        <div v-if="interviewResult.haveVideoQuestion === 1" class="score-star video-star disinh">
          <div class="video-score mb10 flexd" v-if="aiData.isShowAI !== 2">
            <div v-if="isL2ScoreStop"class="posid" @click="showMore = !showMore">
              <span>{{ showMore ? $t('com.report.interviewRecord.text008') : $t('com.report.interviewRecord.text045') }}</span>
              <van-icon name="arrow-up" v-if="showMore" />
              <van-icon name="arrow-down" v-else />
            </div>
            <!-- L5-视频AI总分 L4-总分 -->
            <div class="pr-9" style="min-width: 50px" :class="{ enpr9: langs === 'en' }">
              {{
                aiData.isL5Position === 1
                  ? isXCpositon
                    ? 'L5-AI' + $t('com.report.base.text100')
                    : isMeituanCompany
                    ? $t('com.report.reportV3.text050')
                    : $t('com.report.base.text086')
                  : $t('com.report.base.text028')
              }}:
            </div>
            <template v-if="isXCpositon">
              <template v-if="aiData.isL5Position === 1 && languageScore.l5Star > 0 && aiMarkCfg.allStatus">
                <van-rate v-model="languageScore.l5Star" :size="14" color="#ffd21e" :gutter="2" void-icon="star" void-color="#D1DAF7" readonly />
                <span class="huase" v-if="languageScore.l5Star === 1">{{ $t('com.report.base.wqbsr') }}</span>
                <span class="huase" v-if="languageScore.l5Star === 2">{{ $t('com.report.base.bushengren') }}</span>
                <span class="huase" v-if="languageScore.l5Star === 3">{{ $t('com.report.base.shengren') }}</span>
                <span class="huase" v-if="languageScore.l5Star === 4">{{ $t('com.report.base.youxiu') }}</span>
                <span class="huase" v-if="languageScore.l5Star === 5">{{ $t('com.report.base.chaochuqiwang') }}</span>
              </template>
              <template v-else>{{ $t('com.report.base.text029') }}</template>
            </template>
            <template v-else-if="isMeituanCompany">
              <template v-if="aiData.isL5Position === 1 && aiData.mtL5star > 0 && aiMarkCfg.allStatus">
                <van-rate v-model="aiData.mtL5star" :size="14" color="#ffd21e" :gutter="2" void-icon="star" void-color="#D1DAF7" readonly />
                <span class="huase" v-if="aiData.mtL5star === 1">{{ $t('com.report.base.wqbsr') }}</span>
                <span class="huase" v-if="aiData.mtL5star === 2">{{ $t('com.report.base.bushengren') }}</span>
                <span class="huase" v-if="aiData.mtL5star === 3">{{ $t('com.report.base.shengren') }}</span>
                <span class="huase" v-if="aiData.mtL5star === 4">{{ $t('com.report.base.youxiu') }}</span>
                <span class="huase" v-if="aiData.mtL5star === 5">{{ $t('com.report.base.chaochuqiwang') }}</span>
              </template>
              <template v-else>{{ $t('com.report.base.text029') }}</template>
            </template>
            <template v-else-if="aiData.isL5Position === 1 && aiData.l5Star > 0 && aiMarkCfg.allStatus">
              <van-rate v-model="aiData.l5Star" :size="14" color="#ffd21e" :gutter="2" void-icon="star" void-color="#D1DAF7" readonly />
              <span class="huase" v-if="aiData.l5Star === 1">{{ $t('com.report.base.wqbsr') }}</span>
              <span class="huase" v-if="aiData.l5Star === 2">{{ $t('com.report.base.bushengren') }}</span>
              <span class="huase" v-if="aiData.l5Star === 3">{{ $t('com.report.base.shengren') }}</span>
              <span class="huase" v-if="aiData.l5Star === 4">{{ $t('com.report.base.youxiu') }}</span>
              <span class="huase" v-if="aiData.l5Star === 5">{{ $t('com.report.base.chaochuqiwang') }}</span>
            </template>
            <van-rate v-else-if="aiData.isL5Position === 1 && aiData.l5Star > 0 && !aiMarkCfg.allStatus" :value="0" :size="14" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
            <template v-else-if="aiData.isL5Position === 1 && aiData.tdnaScore === -1">{{ $t('com.report.base.text029') }}</template>
            <template v-else-if="aiData.aiAllScore && aiData.aiAllScore > 0 && aiData.isShowAI && aiData.isAiModel > 0 && aiMarkCfg.allStatus">
              <van-rate v-model="aiData.aiAllScore" :size="14" color="#ffd21e" :gutter="2" void-icon="star" void-color="#D1DAF7" readonly />
              <span class="huase" v-if="aiData.aiAllScore === 1">{{ $t('com.report.base.wqbsr') }}</span>
              <span class="huase" v-if="aiData.aiAllScore === 2">{{ $t('com.report.base.bushengren') }}</span>
              <span class="huase" v-if="aiData.aiAllScore === 3">{{ $t('com.report.base.shengren') }}</span>
              <span class="huase" v-if="aiData.aiAllScore === 4">{{ $t('com.report.base.youxiu') }}</span>
              <span class="huase" v-if="aiData.aiAllScore === 5">{{ $t('com.report.base.chaochuqiwang') }}</span>
            </template>
            <van-rate
              v-else-if="aiData.aiAllScore && aiData.aiAllScore > 0 && aiData.isShowAI && aiData.isAiModel > 0 && !aiMarkCfg.allStatus"
              :value="0"
              :size="14"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
              readonly
            />
            <template v-else>
              <div class="vip-img" v-if="interviewResult.haveVideoQuestion === 1">
                <!-- <img src="@/assets/img/generating.png" v-if="aiData.isShowAI && aiData.isAiModel > 0" /> -->
                <div v-if="aiData.isShowAI && aiData.isAiModel > 0">{{ $t('com.report.base.text029') }}</div>
                <!-- <img src="@/assets/img/dingzhi-mode.png" v-else /> -->
                <div v-else>{{ $t('com.report.base.text030') }}</div>
              </div>
              <van-rate v-else :value="0" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
            </template>
          </div>
          <!-- <div v-if="aiData.isShowAI !== 2" class="divider video-divider" style="height: 16px"></div> -->
          <div class="video-score flexd" v-if="showMore && showAllScore && aiData.isShowAI !== 2">
            <div class="pr-9">
              <!-- L2-AI总分 -->
              <span v-if="aiData.isL5Position === 1">{{ $t('com.report.base.text087') }}:</span>
              <span v-else>{{ isAnyongCompany ? $t('com.report.base.english') : $t('com.report.base.text031') }}:</span>
            </div>
            <template v-if="!l2status && l2score > -1">
              <van-rate v-if="aiMarkCfg.allStatus" v-model="l2score" :size="14" color="#ffd21e" :gutter="2" void-icon="star" void-color="#D1DAF7" readonly />
              <van-rate v-else :value="0" :size="14" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
              <span v-if="!isAnyongCompany" style="margin-left: 5px; line-height: 0">{{ l2PercentageScore }}</span>
            </template>
            <template v-else-if="l2status && isOpenL2">
              <div class="vip-img" v-if="interviewResult.haveVideoQuestion === 1">
                <!-- <img src="@/assets/img/generating.png" v-if="aiData.isShowAI && aiData.isAiModel > 0" /> -->
                <div>{{ $t('com.report.base.text029') }}</div>
                <!-- <img src="@/assets/img/dingzhi-mode.png" v-else /> -->
                <!-- <div v-else>模型训练中</div> -->
              </div>
              <van-rate v-else :value="0" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
            </template>
          </div>
          <!-- <div v-if="isShowAiVideo && aiData.isShowAI !== 2" class="divider video-divider" style="height: 14px"></div> -->
        </div>
        <div class="score-star video-star" v-if="showMore && labelGroupScoreList && labelGroupScoreList.length">
          <div class="video-score" v-if="false && isShowAiVideo && interviewResult.haveVideoQuestion === 1">
            <!-- 视频人工总分 -->
            <div class="pr-9">{{ $t('com.report.base.text032') }}</div>
            <div v-if="(aiData.estimateVideoScore && aiData.estimateVideoScore > 0) || (aiData.estimateTotalScore && aiData.estimateTotalScore > 0)">
              <!--   v-if="aiData.estimateVideoScore && aiData.estimateVideoScore > 0" -->
              <van-rate
                v-if="aiData.estimateVideoScore > 0"
                :value="(aiMarkCfg.manualStatus && aiData.estimateVideoScore) || 0"
                :size="14"
                color="#ffd21e"
                :gutter="2"
                void-icon="star"
                void-color="#D1DAF7"
                readonly
                allow-half
              />
              <van-rate v-else :value="(aiMarkCfg.manualStatus && aiData.estimateTotalScore) || 0" :size="14" color="#ffd21e" :gutter="2" void-icon="star" void-color="#D1DAF7" readonly allow-half />
            </div>
            <van-rate v-else :value="0" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
          </div>
          <!-- <div v-if="labelGroupScoreList && labelGroupScoreList.length && isShowAiVideo && interviewResult.haveVideoQuestion === 1" class="divider video-divider" style="height: 16px"></div> -->
          <div v-if="labelGroupScoreList && labelGroupScoreList.length" class="video-score w100p">
            <!-- 英文笔试总分 考核得分 -->
            <div class="pr-9">{{ isTclEnPostion ? $t('com.report.base.text090') : $t('com.report.base.text033') }}</div>
            <!-- <div v-if="isTclEnPostion">
              {{ totalLabelScore }}<span v-if="isTclEnPostion">/{{ $t('com.report.base.text034', [100]) }}</span>
            </div> -->
            <van-popover v-if="aiData.assessmentScore != -1" v-model="showPopover" trigger="click">
              <div v-for="(labelScore, index) in labelGroupScoreList" :key="index" class="labelscore-item">
                <div class="labelscore-item_label">{{ labelScore.label }}：</div>
                <div class="labelscore-item_value">
                  <span style="color: rgb(24, 144, 255)">{{ labelScore.value || 0 }}</span>
                  <span>/{{ $t('com.report.base.text034', [labelScore.total]) }}</span>
                </div>
              </div>
              <template #reference>
                <div>
                  <span style="color: rgb(24, 144, 255)">{{ aiData.assessmentScore }}</span>
                  <span>/{{ $t('com.report.base.text034', [aiData.assessmentTotalScore]) }}</span>
                </div>
              </template>
            </van-popover>
            <div v-else>{{ $t('com.report.base.text029') }}</div>
          </div>
        </div>
        <div v-if="showMore && showAllScore && interviewResult.haveVideoQuestion === 1" class="score-star ai-star score">
          <div class="ai-score">
            <div class="pr-10">
              <span v-if="isYJLCompany">AI-BQ</span>
              <span v-else>{{ $t('com.report.base.text035') }}</span>
            </div>
            <van-rate
              v-if="aiData.emotionScore && aiData.emotionScore > 0 && aiMarkCfg.allStatus"
              v-model="aiData.emotionScore"
              :size="12"
              color="#ffd21e"
              :gutter="2"
              void-icon="star"
              void-color="#D1DAF7"
              readonly
            />
            <van-rate v-else-if="aiData.emotionScore && aiData.emotionScore > 0 && !aiMarkCfg.allStatus" :value="0" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
            <template v-else>
              <div class="vip-img" v-if="interviewResult.haveVideoQuestion === 1">
                <!-- <img src="@/assets/img/generating.png" /> -->
                <div>{{ $t('com.report.base.text029') }}</div>
              </div>
              <van-rate v-else :value="0" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
            </template>
          </div>
          <!-- <div class="divider" style="height: 16px"></div> -->
          <div class="ai-score" v-if="!isXmzCompany">
            <div class="pr-10">
              <span v-if="isYJLCompany">AI-YZ</span>
              <span v-else>{{ $t('com.report.base.text036') }}</span>
            </div>
            <van-rate
              v-if="aiData.beautyScore && aiData.beautyScore > 0 && aiMarkCfg.allStatus"
              v-model="aiData.beautyScore"
              :size="12"
              color="#ffd21e"
              :gutter="2"
              void-icon="star"
              void-color="#D1DAF7"
              readonly
            />
            <van-rate v-else-if="aiData.beautyScore && aiData.beautyScore > 0 && !aiMarkCfg.allStatus" :value="0" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
            <template v-else>
              <div class="vip-img" v-if="interviewResult.haveVideoQuestion === 1">
                <!-- <img src="@/assets/img/generating.png" /> -->
                <div>{{ $t('com.report.base.text029') }}</div>
              </div>
              <van-rate v-else :value="0" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
            </template>
          </div>
          <!-- <div class="divider" style="height: 16px"></div> -->
          <div class="ai-score" style="margin-top: 10px">
            <div class="pr-10">
              <span v-if="isYJLCompany">AI-SY</span>
              <span v-else>{{ $t('com.report.base.text037') }}</span>
            </div>
            <van-rate
              v-if="aiData.speechScore && aiData.speechScore > 0 && aiMarkCfg.allStatus"
              v-model="aiData.speechScore"
              :size="12"
              color="#ffd21e"
              :gutter="2"
              void-icon="star"
              void-color="#D1DAF7"
              readonly
            />
            <van-rate v-else-if="aiData.speechScore && aiData.speechScore > 0 && !aiMarkCfg.allStatus" :value="0" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
            <template v-else>
              <div class="vip-img" v-if="interviewResult.haveVideoQuestion === 1">
                <!-- <img src="@/assets/img/generating.png" /> -->
                <div>{{ $t('com.report.base.text029') }}</div>
              </div>
              <van-rate v-else :value="0" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
            </template>
          </div>
          <!-- <div v-if="aiData.isShowAI !== 2" class="divider" style="height: 16px; margin-top: 10px"></div> -->
          <div class="ai-score" style="margin-top: 10px" v-if="aiData.isShowAI !== 2">
            <div class="pr-10">{{ $t('com.report.base.text038') }}</div>
            <!--                   v-if="aiData.tdnaScore && aiData.tdnaScore > 0 && aiData.isL5Position === 1"
                  :value="+aiData.tdnaScore"
                  disabled -->
            <van-rate
              v-if="aiData.tdnaScore && aiData.tdnaScore > 0 && aiData.isL5Position === 1 && aiMarkCfg.allStatus"
              v-model="aiData.tdnaScore"
              :size="12"
              color="#ffd21e"
              :gutter="2"
              void-icon="star"
              void-color="#D1DAF7"
              readonly
            />
            <van-rate
              v-else-if="aiData.tdnaScore && aiData.tdnaScore > 0 && aiData.isL5Position === 1 && !aiMarkCfg.allStatus"
              :value="0"
              :size="12"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
              readonly
            />
            <van-rate
              v-else-if="
                aiData.tdnaScore && aiData.tdnaScore > 0 && aiData.isShowAI && aiData.isAiModel && interviewResult.haveVideoQuestion === 1 && +aiData.scoreConfigId !== 7 && aiMarkCfg.allStatus
              "
              v-model="aiData.tdnaScore"
              :size="12"
              color="#ffd21e"
              :gutter="2"
              void-icon="star"
              void-color="#D1DAF7"
              readonly
            />
            <van-rate
              v-else-if="
                aiData.tdnaScore && aiData.tdnaScore > 0 && aiData.isShowAI && aiData.isAiModel && interviewResult.haveVideoQuestion === 1 && +aiData.scoreConfigId !== 7 && !aiMarkCfg.allStatus
              "
              :value="0"
              :size="12"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
              readonly
            />
            <template v-else>
              <div class="vip-img" v-if="interviewResult.haveVideoQuestion === 1">
                <!--                       aiData.isShowAI &&
                      aiData.isAiModel > 0 &&
                      interviewResult.haveVideoQuestion === 1 &&
                      +aiData.scoreConfigId !== 7 生成中 -->
                <div
                  v-if="(aiData.isShowAI && aiData.isAiModel > 0 && interviewResult.haveVideoQuestion === 1 && +aiData.scoreConfigId !== 7) || (aiData.isL5Position === 1 && aiData.tdnaScore === -1)"
                >
                  {{ $t('com.report.base.text029') }}
                </div>
                <!-- <img src="@/assets/img/generating.png" v-if="aiData.isShowAI && aiData.isAiModel > 0" /> -->
                <!-- <img src="@/assets/img/dingzhi-mode.png" v-else /> -->
                <div v-else-if="interviewResult.haveVideoQuestion === 1 || +aiData.scoreConfigId === 7">{{ $t('com.report.base.text030') }}</div>
                <van-rate v-else :size="12" color="#ffd21e" :gutter="2" void-icon="star" void-color="#D1DAF7" readonly></van-rate>
              </div>
              <van-rate v-else :value="0" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
            </template>
          </div>
        </div>
      </div>
      <div class="base-nfo_tags" v-if="showMore && jobseekerChannelId">
        <report-tags :interviewResult="interviewResult" :jobseekerChannelId="jobseekerChannelId" v-if="jobseekerChannelId"></report-tags>
      </div>
      <div class="line2 nofinish" v-if="showMore && estimateType !== 2 && (estimateType === 1 || !inviteAIInterviewFlag || !aiDataFlag) && false">
        <div class="warning"><i class="iconfont">&#xe670;</i></div>
        <div class="text">{{ !inviteAIInterviewFlag ? $t('com.report.base.text039') : $t('com.report.base.text040') }}</div>
      </div>
    </div>

    <div class="tabs-list" v-show="!isDwdTest" ref="tabBar">
      <ul class="tabs">
        <li id="tab-0" :class="{ active: tabIndex === 0 }" @click="updateTabIndex(0)" v-if="permissionsTreeId.includes('reportResume')">
          <div>{{ $t('com.report.base.text007') }}</div>
        </li>
        <li
          id="tab-1"
          :class="{ active: tabIndex === 1 }"
          @click="updateTabIndex(1)"
          v-if="
            permissionsTreeId.includes('reportAIInterview') &&
            (showAllAiTab || (!showAllAiTab && currentPerson && answerResult && answerResult.length && hasVideoQues && hasVideoQues.length)) &&
            interviewResult.haveAi === 1
          "
        >
          <div>{{ $t('com.report.base.text008') }}</div>
        </li>
        <!-- <li :class="{ active: tabIndex === 2 }" @click="updateTabIndex(2)" v-if="interviewResult.haveAi === 1">
					<div>AI报告</div>
				</li> -->

        <li
          id="tab-17"
          :class="{ active: tabIndex === 17 }"
          @click="updateTabIndex(17)"
          v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && zxbsHasData)) && interviewResult.haveAi === 1"
        >
          <div>{{ $t('com.report.base.text009') }}</div>
        </li>
        <li
          id="tab-18"
          :class="{ active: tabIndex === 18 }"
          @click="updateTabIndex(18)"
          v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && rznlHasData)) && interviewResult.haveAi === 1"
        >
          <div>{{ $t('com.report.base.text010') }}</div>
        </li>
        <li
          id="tab-19"
          :class="{ active: tabIndex === 19 }"
          @click="updateTabIndex(19)"
          v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && zyxgHasData)) && interviewResult.haveAi === 1"
        >
          <div>{{ $t('com.report.base.text011') }}</div>
        </li>
        <li
          id="tab-21"
          :class="{ active: tabIndex === 21 }"
          @click="updateTabIndex(21)"
          v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && meritasHasData)) && interviewResult.haveAi === 1 && meritasShow"
        >
          <div>{{ $t('com.report.base.meritas') }}</div>
        </li>
        <li
          id="tab-20"
          :class="{ active: tabIndex === 20 }"
          @click="updateTabIndex(20)"
          v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && xljkHasData)) && interviewResult.haveAi === 1"
        >
          <div>{{ $t('com.report.base.text012') }}</div>
        </li>
        <li
          id="tab-16"
          :class="{ active: tabIndex === 16 }"
          @click="updateTabIndex(16)"
          v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && dmksHasData)) && interviewResult.haveAi === 1"
        >
          <div>{{ $t('com.report.base.text013') }}</div>
        </li>

        <li id="tab-12" v-if="isZj" :class="{ active: tabIndex === 12 }" @click="updateTabIndex(12)">
          <div>{{ $t('com.report.base.text014') }}</div>
        </li>
        <li id="tab-5" v-if="permissionsTreeId.includes('thirdComment') && !isZj" :class="{ active: tabIndex === 5 }" @click="updateTabIndex(5)">
          <div>{{ $t('com.report.base.text015') }}</div>
        </li>
        <li id="tab-11" v-if="isZsCompany" :class="{ active: tabIndex === 11 }" @click="updateTabIndex(11)">
          <div>{{ $t('com.report.base.text016') }}</div>
        </li>
        <li id="tab-3" :class="{ active: tabIndex === 3 }" @click="updateTabIndex(3)" v-if="permissionsTreeId.includes('planInterview')">
          <div>{{ $t('com.report.base.text017') }}</div>
        </li>
        <li id="tab-4" :class="{ active: tabIndex === 4 }" @click="updateTabIndex(4)" v-if="permissionsTreeId.includes('reportRemark')">
          <div :id="remarkList && remarkList.length > 0 ? 'mark_label_2' : ''" :data-num="remarkList && remarkList.length > 0 ? '(' + remarkList.length + ')' : ''">
            {{ $t('com.report.base.text018') }}
          </div>
        </li>
        <li id="tab-6" :class="{ active: tabIndex === 6 }" @click="updateTabIndex(6)" v-if="showAllAiTab || (!showAllAiTab && attachInfo && attachInfo.length)">
          <div>{{ $t('com.report.base.text019') }}</div>
        </li>
        <li id="tab-22" :class="{ active: tabIndex === 22 }" @click="updateTabIndex(22)" v-if="permissionsTreeId.includes('reportAIInterview') && (showAllAiTab || (!showAllAiTab && fzbHasData))">
          <div>{{ $t('com.report.base.text088') }}</div>
        </li>
      </ul>
      <ul class="small-tabs" v-if="tabIndex === 0 && resumeTab && resumeTab.length > 1 && !isAnonymous">
        <li v-for="(tabItem, tabIndex) in resumeTab" :key="tabIndex" :class="{ active: activeIndex === tabIndex }" @click="updatedActiveIndex(tabIndex, tabItem)">{{ tabItem.text }}</li>
        <div class="tabs__line" :style="{ transform: 'translateX(' + (100 / resumeTab.length / 4 + (100 / resumeTab.length) * activeIndex) + 'vw )', width: 100 / resumeTab.length / 2 + 'vw' }"></div>
      </ul>
      <div class="repeat-interview" v-if="tabIndex === 1 && interviewInfos && interviewInfos.length && interviewInfos.length > 1" @click="handleClick">
        <div class="re-left">
          <i18n path="com.report.base.text020" tag="span">
            <span place="count" style="color: #1890ff"> {{ interviewInfos.length }} </span>
          </i18n>
          <!-- 该候选人共面试了<span place="count" style="color: #1890ff"> {{ interviewInfos.length }} </span>次 -->
        </div>
        <div>{{ currentInterviewInfo.interviewName }} <van-icon name="arrow-down" /></div>
      </div>
      <ul class="small-tabs disnone" v-if="tabIndex === 1">
        <li :class="{ active: activeIndex === 0 }" @click="updatedActiveIndex(0)">{{ $t('com.report.base.text021') }}</li>
        <li :class="{ active: activeIndex === 1 }" @click="updatedActiveIndex(1)">{{ $t('com.report.base.text022') }}</li>

        <!-- <li :class="{ active: activeIndex === 2 }" @click="updatedActiveIndex(2)">在线考试记录</li> -->
        <div class="tabs__line" :style="{ transform: 'translateX(' + (100 / 2 / 4 + (100 / 2) * activeIndex) + 'vw )', width: 100 / 2 / 2 + 'vw' }"></div>
      </ul>
      <ul class="small-tabs" v-if="tabIndex === 17">
        <li :class="{ active: activeIndex === 0 }" @click="updatedActiveIndex(0)">{{ $t('com.report.base.text023') }}</li>
        <li :class="{ active: activeIndex === 1 }" @click="updatedActiveIndex(1)">{{ $t('com.report.base.text024') }}</li>
        <li :class="{ active: activeIndex === 2 }" @click="updatedActiveIndex(2)">{{ $t('com.report.base.text025') }}</li>
        <li :class="{ active: activeIndex === 3 }" @click="updatedActiveIndex(3)">{{ $t('com.report.base.text0231') }}</li>
        <div class="tabs__line" :style="{ transform: 'translateX(' + (100 / 4 / 4 + (100 / 4) * activeIndex) + 'vw )', width: 100 / 4 / 2 + 'vw' }"></div>
      </ul>
    </div>
    <div class="tab-content">
      <div v-if="tabIndex === 0 && !isAnonymous">
        <ResumeContent
          @updateShowActiveIndex="scrollUpdateShowActiveIndex"
          :scrollTop="scrollTop"
          :activeIndex="activeIndex"
          :tabBarHeight="tabBarHeight"
          :baseInfoHeight="baseInfoHeight"
          @updateItemScrollHeight="updateItemScrollHeight"
          @updateScrollTop="updateScrollTop"
          :resumeTab="resumeTab"
          v-if="!resumeUrlLoading"
          @removeCanidateForm="removeCanidateForm"
          :resumeIndex="resumeIndex"
          :tabItem="tabItem"
        />
        <div v-if="resumeUrlLoading" style="display: flex; justify-content: center; height: 200px; flex-direction: column; align-items: center">
          <img height="100px" width="100px" src="@/assets/ai-loading.gif" />
          <div>{{ $t('com.report.base.text041') }}</div>
        </div>
      </div>
      <Empty v-if="tabIndex === 0 && isAnonymous" :title="$t('com.report.base.text042')"></Empty>

      <InterviewRecordContent
        @updateShowActiveIndex="scrollUpdateShowActiveIndex"
        :scrollTop="scrollTop"
        :activeIndex="activeIndex"
        :tabBarHeight="tabBarHeight"
        :baseInfoHeight="baseInfoHeight"
        v-if="tabIndex === 1"
      />

      <!-- <NewReport v-if="tabIndex === 2" /> -->
      <InterviewPlanContent v-if="tabIndex === 3" :isCheckOwner="interviewPlanOption.isCheckOwner" />
      <offline-interview v-if="tabIndex === 11" :jobseekerChannelId="jobseekerChannelId"></offline-interview>
      <RemarkContent v-if="tabIndex === 4" />
      <thirdPartyEvaluation v-if="tabIndex === 5" :positionId="positionId" :jobseekerChannelId="jobseekerChannelId" />
      <AttachInfo v-if="tabIndex === 6" :jobseekerChannelId="jobseekerChannelId" />
      <PdpTest v-if="tabIndex === 12" />

      <CodingObjective v-if="+tabIndex === 16" :problemType="{ label: 'coding', name: $t('com.report.base.text043'), types: [7] }" />

      <OnlineTest v-if="+tabIndex === 17" :activeIndex="activeIndex" />

      <CognitiveAbility v-if="+tabIndex === 18" />

      <ProfessionalCharacter v-if="+tabIndex === 19" />

      <!-- meritas报告 -->
      <MeritasView v-if="+tabIndex === 21" />

      <MentalHealth v-if="+tabIndex === 20" />

      <!-- 防作弊记录 -->
      <AntiCheating v-if="+tabIndex === 22"></AntiCheating>
    </div>
    <!--面试官筛选简历-->
    <div class="footer2" v-if="recommendStageId">
      <div class="other-group">
        <div v-if="isShowAiVideo" class="other-icon-item" @click="showList = true">
          <img src="@/assets/img/recommend-icon.png" />
          <div>{{ $t('com.report.base.text044') }}</div>
        </div>

        <div
          :id="allEstmate && allEstmate.length ? 'allEstimateList' : ''"
          class="other-icon-item estimate-item hidden-data-num"
          @click="showEstimateDialogClick()"
          v-if="(userInfo && userInfo.isShowEvaluate === 1) || +urlType === 1"
        >
          <img src="@/assets/img/estimate-icon.png" />
          <div>{{ $t('com.report.base.text045') }}</div>
        </div>
      </div>

      <div class="btn-group" v-if="+urlType !== 2 && showRecommendAction">
        <div v-if="+urlType !== 2" class="btn-item pass" :class="{ 'pass-active': interviewerOperateStatus === 1 }" @click="openEstimateType(1)">
          {{ interviewerOperateStatus === 1 ? $t('com.report.base.text047') : $t('com.report.base.text046') }}
        </div>
        <div v-if="+urlType !== 2" class="btn-item wait" :class="{ 'wait-active': interviewerOperateStatus === 4 }" @click="openEstimateType(4)">
          {{ interviewerOperateStatus === 4 ? $t('com.report.base.text049') : $t('com.report.base.text048') }}
        </div>
        <div v-if="+urlType !== 2" class="btn-item reject" :class="{ 'reject-active': interviewerOperateStatus === 2 }" @click="openEstimateType(2)">
          {{ interviewerOperateStatus === 2 ? $t('com.report.base.text051') : $t('com.report.base.text050') }}
        </div>
      </div>
    </div>

    <!--面试官接受或者拒绝hr的面试安排-->
    <div class="footer" v-if="estimateIndex === 2 && false">
      <!-- 修改bug10105 -->
      <van-button plain type="primary" class="gray" @click="showRefuseDialog = true">{{ $t('com.report.base.text052') }}</van-button>
      <van-button plain type="primary" class="blue" @click="setInterviewerReceiveStatus(1)">{{ $t('com.report.base.text053') }}</van-button>
    </div>

    <!-- 分享报告 -->
    <van-dialog v-model="showEmailDialog" closeOnClickOverlay closeOnPopstate :show-confirm-button="false" :title="$t('com.report.base.text054')">
      <van-cell-group>
        <van-field v-model="email" :label="$t('com.report.base.text055')" :placeholder="$t('com.report.base.text054')" />
      </van-cell-group>
      <van-button class="van-button--large van-dialog__confirm" @click="validateEmail">{{ $t('com.report.base.text056') }}</van-button>
    </van-dialog>
    <!-- showEstimateDialog = false -->
    <EstimateDialog @close="estimateClose" @success="setOperateStatus(operateChannel)" v-if="showEstimateDialog" />
    <EstimateRecordDialog @close="closeRecordDialog" v-if="showEstimateRecordDialog" />
    <account-list v-if="showList" @close="showList = false" @change="transmitResume" :showList="showList"></account-list>

    <van-dialog v-model="showDatePicker" theme="round-button" :showConfirmButton="false">
      <div class="show_datepicker">
        <van-icon name="close" class="close-icon" @click="cancleToDatePicker" />
        <img src="@/assets/complete.png" width="50px" height="50px" />
        <div class="accept">{{ $t('com.report.base.text057') }}</div>
        <div class="is_interview">{{ $t('com.report.base.text058') }}</div>
        <div class="nomore">
          <van-checkbox shape="square" v-model="noMoreTip">{{ $t('com.report.base.text059') }}</van-checkbox>
        </div>
        <div class="btns">
          <van-button round @click="cancleToDatePicker">{{ $t('com.report.base.text060') }}</van-button>
          <van-button type="info" round @click="toDatePicker">{{ $t('com.report.base.text061') }}</van-button>
        </div>
      </div>
    </van-dialog>
    <van-popup v-model="showPreviewer" round closeable position="bottom" :style="{ height: '80%' }">
      <DocPreviewer :url="currentPreviewUrl" minHeight="100%" />
    </van-popup>
    <van-popup v-model="showInterview" round position="bottom" :style="{ height: '60%' }">
      <van-picker show-toolbar :columns="interviewInfos" :value-key="'interviewName'" :default-index="defaultIndex" @cancel="showInterview = false" @confirm="onConfirm" />
    </van-popup>
    <div v-if="imgLoading" class="imgLoading" style="background: #fff">
      <img v-if="imgLoading" height="100px" width="100px" src="@/assets/ai-loading.gif" />
      <div v-if="imgLoading">简历解析中</div>
    </div>
  </div>
</template>
<script>
// import host from '../config/host'
import EventBus from '@/common/event-bus'
import { educationInfo } from '@/assets/js/keyToName'
import ResumeContent from '@/components/reportContent/ResumeContent'
import InterviewRecordContent from '@/components/reportContent/InterviewRecordContent'
import InterviewPlanContent from '@/components/reportContent/InterviewPlanContent'
import RemarkContent from '@/components/reportContent/RemarkContent'
import AttachInfo from '@/components/reportContent/attach-info.vue'
// import AIEstimateContent from '@/components/reportContent/AIEstimateContent'
// import NewReport from '@/components/reportV3/index.vue'
import DocPreviewer from '@/components/doc-previewer/doc-previewer'
import PdpTest from '@/components/pdp-test/index'
import CognitiveAbility from '@/components/reportV3/cognitive-ability.vue'
import MentalHealth from '@/components/reportV3/mental-health.vue'
import ProfessionalCharacter from '@/components/reportV3/professional-character.vue'
import MeritasView from '@/components/reportV3/meritasView.vue'
import CodingObjective from '@/components/reportV3/coding-objective.vue'
import OnlineTest from '@/components/reportV3/online-test.vue'
import Empty from '@/components/reportV3/common/empty.vue'
import AntiCheating from '@/components/reportV3/antiCheating/index'

import {
  jobSeekerChannelOperate,
  getJobseekerId,
  interviewerReceiveStatus,
  getInterviewerReceiveStatus,
  getAiNoticeStatus,
  jobSeekerChannelDetailStatus,
  getInterviewInfos,
  queryCandidateAttachInfo,
} from '@/api/candidate'
import moment from 'moment'
import {
  interviewInfo,
  findId,
  sendMsg,
  findAllWorkInfo,
  findAllEducation,
  getProjectExperience,
  jobseekerChannelInfo,
  convertFile,
  report4MobileV2,
  jobseekerResumeInfo,
  shareByEmail,
  transmitResume,
} from '../api/report'
import thirdPartyEvaluation from '@/components/thirdPartyEvaluation'
import ReportTags from './report-tags'
import EstimateDialog from '@/components/estimate-dialog.vue'
import EstimateRecordDialog from '@/components/estimate-record-dialog.vue'
import accountList from '@/components/account-list/index.vue'
import { updateWechatHint } from '@/api/admin'
import offlineInterview from '@/components/offline-interview/index.vue'
import {
  selectAllEstimate,
  selectAllEstimateGroupBy,
  selectAllEstimateGroupByforCMBC,
  selectEstimateGroupByTimePeriod,
  clickEstimate,
  getAntiCheatingInfo,
  getAntiCheatingLogs,
  getAntiCheatingImages,
  getLanguageScore,
} from '@/api/mark'
import { getResumeUrlList, getCheatLogs } from '@/api/candidate'
import { getMeritasReport } from '@/api/mark.js'
import { getUserInfo, getUserPermissions, getResumeLabelConfig, getAiInterLabelConfig, getSystemConfigList } from '@/api/admin'
import i18n from '../utils/i18n'

export default {
  props: {
    isAnonymous: {
      type: Boolean,
      default: false,
    },
    interviewPlanOption: {
      type: Object,
      default() {
        return {
          isCheckOwner: false,
        }
      },
    },
  },
  components: {
    ResumeContent,
    InterviewRecordContent,
    // NewReport,
    InterviewPlanContent,
    RemarkContent,
    thirdPartyEvaluation,
    ReportTags,
    EstimateDialog,
    EstimateRecordDialog,
    accountList,
    offlineInterview,
    AttachInfo,
    DocPreviewer,
    PdpTest,
    CognitiveAbility,
    MentalHealth,
    ProfessionalCharacter,
    CodingObjective,
    OnlineTest,
    Empty,
    MeritasView,
    AntiCheating,
  },
  data() {
    return {
      showMore: false,
      showLangChange: false,
      languageActions: [
        //
        { name: i18n.t('com.report.base.text062'), lang: 'cn' },
        { name: 'English', lang: 'en' },
        { name: i18n.t('com.report.base.text063'), lang: 'zh-TW' },
      ],
      imgLoading: true,
      periodTime: '',
      periodLength: 0,
      showEmailDialog: false,
      moment: moment,
      aiScore2: 2,
      aiScore: 4,
      showActiveIndex: 0,
      showReport: false,
      baseInfo: {
        name: '',
        phone: '',
        email: '',
        position: '',
        workYear: 3,
      },
      imgUrl: '',
      activeIndex: 0,
      tabIndex: 0,
      scrollTop: 0,
      tabBarHeight: 0,
      baseInfoHeight: 0,
      shareClickFlag: false,
      shareUrl: '',
      cid: '',
      resumeInfo: '',
      source: 0,
      aiTabArray: [],
      interviewResultFlag: false,
      aiDataFlag: false,
      showFixTab: false,
      email: '',
      estimateIndex: 0,
      estimateContent: '',
      showRefuseDialog: false,
      inviteAIInterviewFlag: true,
      resumeTab: [],
      //阶段Id
      //stageId: '',
      resumeIndex: 0,
      showEstimateDialog: false,
      showEstimateRecordDialog: false,
      choseOperateStatusIndex: 0,
      interviewerOperateStatus: 0,
      screenTipsContainerFlod: true,
      showList: false,
      operateChannel: '',
      showDatePicker: false,
      noMoreTip: false,
      zsCompanyId: [
        // '2565243e57df433da761e20a57fe030c',
        '0a4e956ededb406cae3cb394dac9e78f', //招商正式区
        '686d823e59304ffe953b2d548cf6819c',
        // '295eb58474a84b8f9409f6b5621b04ef', //mini
        // 'bb96166e77be41e8a18d30e28ba63199', //mini
        // '295eb58474a84b8f9409f6b5621b04ef', //正式区  测试17321346370
      ],
      /** urlType 1-推荐筛选-转发 2-安排面试 */
      urlType: '',
      currentPerson: '',
      tabItem: {},
      resumeUrlLoading: false,
      showPreviewer: false,
      currentPreviewUrl: '',
      showPopover: false,
      transferWorkSyncAiData: 0,
      showInterview: false,
      currentInterviewInfo: {},
      defaultIndex: 0,
      meritasShow: false, // meritas是否显示
      provideObj: {}, // 要往子孙组件传递的信息，目前包含防作弊记录信息
      tabsMap: [
        //1069:AI视频面试，包括 AI视频面试，在线笔试，代码考试，认知能力，职业性格，心理健康，meritas，防作弊等tab
        { id: 723, name: '职位管理', code: 'position' },
        { id: 744, name: '流程阶段', code: 'procedure' },
        { id: 755, name: '候选人管理', code: 'candidate' },
        { id: 1067, name: '简历信息', code: 'reportResume' },
        { id: 1069, name: 'AI视频面试', code: 'reportAIInterview' },
        { id: 777, name: '安排面试', code: 'planInterview' },
        { id: 787, name: '第三方测评', code: 'thirdComment' },
        { id: 790, name: 'offer', code: 'offer' },
        { id: 798, name: '背调', code: 'background' },
        { id: 1071, name: '备注', code: 'reportRemark' },
        { id: 1073, name: '操作记录', code: 'reportInterviewLog' },
        { id: 801, name: '人才库', code: 'talent' },
        { id: 811, name: '报表中心', code: 'reportCenter' },
        { id: 813, name: '人工评估', code: 'manualEvaluation' },
        { id: 815, name: '企业题库', code: 'questionBank' },
        { id: 817, name: 'AI运营大盘', code: 'overviewAI' },
      ],
      permissionsTreeId: [],
      showAllAiTab: true, //AI视频面试 true:展示包含的所有tab false:仅展示包含的有数据的tab
      showAllScore: true, // false:仅显示L5/L4视频得分 true:显示所有得分
      meritasHasData: true,
      recordData: {
        cheatLogs: [],
      },
      attachInfo: [],
      languageScore: {},
      isDwdTest: false, // 是否是小程序多维度测评
      isL2ScoreStop: 0,
    }
  },
  provide() {
    return {
      provideInfo: this.provideObj,
    }
  },
  watch: {
    interviewResult: {
      handler(now, old) {
        this.$nextTick(() => {
          if (now.interviewId && !old?.interviewId) {
            this.initActab()
          }
        })
      },
      immediate: true,
      deep: true,
    },
    showManualEstimateDialog(val) {
      if (!val) {
        this.getAIReportData()
      }
    },
    tabIndex() {
      console.log('tabIndex++++++++++++++++++', this.tabIndex)
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      // if (val === 0 && !this.previewResumeUrl) {
      //   this.activeIndex = 1
      // } else {
      this.activeIndex = 0

      this.showActiveIndex = 0
    },
    canInitAIData: {
      handler: function (val) {
        if (val) {
          this.initAITab()
        }
      },
      immediate: true,
    },
    remarkList: {
      immediate: true,
      deep: true,
      handler() {
        this.setBrfore()
      },
    },
    allEstmate: {
      immediate: true,
      deep: true,
      handler() {
        this.setBrfore()
      },
    },
  },
  created() {
    if (this.token) {
      this.getUserInfo()
    }
    this.getAiTabsConfig(this.companyId)
    this.getSysCfgList(this.companyId)
    EventBus.$on('openPreviewer', url => this.onOpenPreviewer(url))
    this.setBrfore()
    this.$store.commit('clearData')
    if (!navigator.onLine) {
      this.$store.commit('setNoWifi', true)
    }
    this.imgLoading = true
    getJobseekerId({ jobseekerChannelId: this.$route.query.jobseekerChannelId || this.$route.query.userid })
      .then(res => {
        if (res && res.code === 0 && res.data && res.data.interviewId) {
          this.$store.commit('setInterviewId', res.data.interviewId)
          this.$store.commit('setJobseekerChannelId', this.$route.query.jobseekerChannelId || this.$route.query.userid)
          this.getAntiCheating() // 获取防作弊记录信息
          this.getAntiCheatingLogs() // 获取防作弊日志信息
          this.getAntiCheatingImages() // 获取防作弊监控照片
          this.listEstimate()
          this.getAllEstimate()
          if (!this.$route.query?.userid) {
            this.getTabPerm(res.data.interviewId, this.$route.query.jobseekerChannelId)
          }
        }
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => {
        this.initData()
      })
    queryCandidateAttachInfo({ jobSeekerChannelId: this.$route.query?.jobseekerChannelId }).then(res => {
      if (res.code === 0) {
        this.attachInfo = res.data || []
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollEvent)
  },
  mounted() {
    const interviewId = this.$route.query?.userid
    const jobseekerChannelId = this.$route.query?.jobseekerChannelId ?? ''
    this.getTabPerm(interviewId, jobseekerChannelId)
    this.$nextTick(() => {
      this.tabBarHeight = this.$refs.tabBar.offsetHeight
      this.baseInfoHeight = this.$refs.baseInfo.offsetHeight
      this.setBrfore()
    })
    window.addEventListener('scroll', this.scrollEvent)
    // this.getAuth()
  },
  computed: {
    langs() {
      return this.$store.state.lang || 'cn'
    },
    aiMarkCfg() {
      return this.$store.state.aiMarkCfg
    },
    // l2-总分的分数
    l2PercentageScore() {
      return this.l2ReportInfo?.l2PercentageScore
    },
    l2status() {
      return this.l2ReportInfo?.l2status || 0
    },
    l2score() {
      return this.l2ReportInfo?.l2score || 0
    },
    l2ReportInfo() {
      return this.aiData?.l2ReportInfo
    },
    isOpenL2() {
      const isOrderAi = this.aiData?.isOrderAi || this.$store.state.companyInfo?.isOrderAi
      return isOrderAi === 2 || isOrderAi === 3
    },
    totalLabelScore() {
      const arr = this.labelGroupScoreList || []
      let total = 0
      arr.forEach(item => {
        total += item.value
      })
      return total
    },

    labelGroupScoreList() {
      return (this.aiData?.labelGroupScoreList || []).map(item => {
        return { text: `${item.label}：${item.labelScore}分`, label: item.label, value: item.labelScore, total: item.labelTotalScore }
      })
    },
    isXmzCompany() {
      const companyId = this.$store.state.companyId
      return this.$store.state.xmzCompanyId.includes(companyId)
    },
    isZj() {
      const companyId = this.$store.state.companyId
      return this.$store.state.userInfo?.customerType === 'CUSTOMERTYPE_ZHONGJUN' || this.$store.state.zjCompanyId.includes(companyId)
    },
    isShowAiVideo() {
      // 0 招商 1 正常
      const isShowAiVideo = this.$store.state.isShowAiVideo

      return isShowAiVideo === 1 ? true : false
      // if (this.$store.state.userInfo) {
      //   return Number(this.$store.state.userInfo.isShowAiVideo) === 1 ? true : false
      // } else {
      //   return Number(this.currentPerson.isShowAiVideo) === 1 ? true : false
      // }
    },
    isZsCompany() {
      const companyId = this.$store.state.companyId
      return this.zsCompanyId.includes(companyId) ? true : false
      // return Number(this.$store.state.userInfo.isShowAiVideo) === 1 ? false : true
    },
    isYJLCompany() {
      const companyId = this.$store.state.companyId
      return this.$store.state.yjlCompanyId.includes(companyId) ? true : false
    },
    // 是否是安永公司
    isAnyongCompany() {
      const companyId = this.$store.state.companyId
      return this.$store.state.anyongCompanyId.includes(companyId) ? true : false
    },
    // // 是否是携程公司
    // isXieChengCompany() {
    //   const companyId = this.$store.state.companyId
    //   if(this.$store.state.xiechengCompanyId.includes(companyId)){
    //     this.getLanguageScore()
    //     return true
    //   }else{
    //     return false
    //   }
    // },
    //携程特定职位显示总分
    isXCpositon() {
      const positionid = this.interviewResult?.positionid
      if (
        [
          '7923f8dbd5b84a36b73512107c4453fe',
          '1dea4b1c04664cdd9f59e9c01d614b79',
          '941cd95301c541a4900222113b460f5b',
          'be6ea142f6884da68ea9e887bf016f6f', //正式
          '60b31ac3658e42548a88fa045da3d26e', //mini
          '9255bd6c2cc2425491d217571d634893', //mini
          'b893d304c41d4a9d925598cbbb0a594a', //mini
          '9f23c660e8864921903857f4d9841b21', //mini
          '8b52e0440add44d39708744c24c64a2f', //uat
          '930819c00a0442c19b99bb382a59e317', //uat
          'e384a3d3431a4e7abadce5cd2df1a7d8', //uat
          'e55c4e03fb324870a372bf31f74c2fd5', //uat
        ].includes(positionid)
      ) {
        this.getLanguageScore()
        return true
      } else {
        return false
      }
    },

    //美团特定
    isMeituanCompany() {
      const companyId = this.$store.state.companyId
      const companyIdArr = [
        'ca9375a3d8004f2aae0b368ba0da3747', //prod
        '22af0e093964421e81357904e3bb0871', //uat
        '1ea5c8683b9240d689f3452fa6568b1b', //mini
      ]
      return companyIdArr.includes(companyId) ? true : false
    },
    recommendStageId() {
      return this.$route.query.recommendStageId
    },
    stageTypeKind() {
      return this.$route.query.stageTypeKind
    },
    stageId() {
      if (this.interviewResult && this.interviewResult.stageId) {
        return this.interviewResult.stageId
      } else {
        return ''
      }
    },
    isTclEnPostion() {
      const positionid = this.interviewResult?.positionid
      return this.$store.state.tclEnPostionIds.includes(positionid)
    },
    haveOperateCount() {
      return this.$store.state.haveOperateCount
    },
    noOperateCount() {
      return this.$store.state.noOperateCount
    },
    invalidCount() {
      return this.$store.state.invalidCount
    },

    avatarUrl() {
      if (this.interviewResult && this.interviewResult.url) {
        return this.interviewResult.url
      } else {
        if (!this.interviewResult || this.interviewResult.gender === null || this.interviewResult.gender === undefined) {
          return require('@/assets/img/unknown-gender.png')
        } else {
          if (this.interviewResult.gender === 1) {
            return require('@/assets/img/female.png')
          } else {
            return require('@/assets/img/man.png')
          }
        }
      }
    },
    interviewPlanId() {
      return this.$store.state.interviewPlanId
    },
    showManualEstimateDialog() {
      return this.$store.state.showManualEstimateDialog
    },
    token() {
      return this.$store.state.token || this.$store.state.userInfo?.token
    },
    aiData() {
      if (this.$store.state.aiData) {
        return this.$store.state.aiData
      } else {
        return {}
      }
    },
    previewResumeUrl() {
      return this.$store.state.previewResumeUrl
    },
    estimateType() {
      return this.$store.state.estimateType
    },
    positionId() {
      return this.$store.state.positionId
    },
    companyId() {
      return this.$store.state.companyId || this.$route.query.companyId
    },
    interviewResult() {
      return this.$store.state.interviewResult
    },
    isFinished() {
      return this.interviewResult?.interview_status === 'finish'
    },
    answerResult() {
      return this.$store.state.answerResult || []
    },
    hasVideoQues() {
      return this.answerResult.filter(r => {
        const info = Array.isArray(r) ? r[0] : r
        return [1, 6].includes(+info.question?.problem_answer_method)
      })
    },
    interviewId() {
      if (this.isFrom === 'miniapp' && this.$route.query?.userid) {
        return this.$route.query?.userid
      }
      return this.$store.state.interviewId
    },
    jobseekerChannelId() {
      return this.$store.state.jobseekerChannelId || this.$store.state.interviewResult.jobseekerChannelId
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    corpId() {
      return this.$store.state.corpId
    },
    appId() {
      return this.$store.state.appId
    },
    canInitAIData() {
      if (this.interviewResultFlag && this.aiDataFlag) {
        return true
      } else {
        return false
      }
    },
    shortList() {
      return this.$store.state.shortList
    },
    canBack() {
      return this.$route.query.canBack === '1'
    },
    // isWechatHint() {
    //   return this.$store.state.userInfo.isWechatHint
    // },
    remarkList() {
      return this.$store.state.remarkList
    },
    allEstmate() {
      return this.$store.state.allEstmate
    },
    showUpDowm() {
      return this.$route.path !== 1
    },
    routeLength() {
      return this.$store.state.routeLength || 1
    },
    routeFrom() {
      return this.$route.query.from
    },
    isMobile() {
      return /(mobile|nokia|iphone|ipad|android|samsung|htc|blackberry)/.test(navigator.userAgent.toLowerCase())
    },
    stageTypeId() {
      return this.interviewResult.stageTypeId
    },
    showRecommendAction() {
      return this.interviewResult?.showRecommendAction === 1 && this.currentStageTypeId !== 8 && this.currentStageTypeId !== 9
    },
    interviewInfos() {
      return this.$store.state.interviewInfos || []
    },
    reportDim() {
      return this.$store.state.companyInfo?.reportDim
    },
    zxbsHasData() {
      //在线笔试(text009)有数据
      const arr = this.answerResult.filter(item => {
        const info = Array.isArray(item) ? item[0] : item
        return [0, 2, 3, 4, 5, 8, 11].includes(+info.question?.problem_answer_method)
      })
      return arr && arr.length > 0
    },
    rznlHasData() {
      //认知能力(text010)有数据
      return this.aiData && this.aiData.realizeScore && (Object.keys(this.aiData.realizeScore).length > 0 || this.aiData.realizeScore.realizeComment1)
    },
    showEcharts() {
      const arr = Object.keys(this.aiData.characterScore || {})
      return arr && arr.length > 0
    },
    zyxgHasData() {
      //职业性格(text011)有数据
      let tableList = []
      let totalText = []
      if (this.showEcharts) {
        tableList = [
          {
            dimension: this.$t('com.report.professional.text013'),
            lowCharacter: this.$t('com.report.professional.text020'),
            highCharacter: this.$t('com.report.professional.text021'),
            percent: this.aiData?.characterScore?.social,
            comment: this.aiData?.characterScore?.socialComment,
          },
          {
            dimension: this.$t('com.report.professional.text012'),
            lowCharacter: this.$t('com.report.professional.text022'),
            highCharacter: this.$t('com.report.professional.text023'),
            percent: this.aiData?.characterScore?.adapt,
            comment: this.aiData?.characterScore?.adaptComment,
          },
          {
            dimension: this.$t('com.report.professional.text016'),
            lowCharacter: this.$t('com.report.professional.text024'),
            highCharacter: this.$t('com.report.professional.text025'),
            percent: this.aiData?.characterScore?.curious,
            comment: this.aiData?.characterScore?.curiousComment,
          },
          {
            dimension: this.$t('com.report.professional.text018'),
            lowCharacter: this.$t('com.report.professional.text026'),
            highCharacter: this.$t('com.report.professional.text027'),
            percent: this.aiData?.characterScore?.harmonious,
            comment: this.aiData?.characterScore?.harmoniousComment,
          },
          {
            dimension: this.$t('com.report.professional.text015'),
            lowCharacter: this.$t('com.report.professional.text028'),
            highCharacter: this.$t('com.report.professional.text029'),
            percent: this.aiData?.characterScore?.duty,
            comment: this.aiData?.characterScore?.dutyComment,
          },
          {
            dimension: this.$t('com.report.professional.text014'),
            lowCharacter: this.$t('com.report.professional.text030'),
            highCharacter: this.$t('com.report.professional.text031'),
            percent: this.aiData?.characterScore?.ambition,
            comment: this.aiData?.characterScore?.ambitionComment,
          },
          {
            dimension: this.$t('com.report.professional.text017'),
            lowCharacter: this.$t('com.report.professional.text032'),
            highCharacter: this.$t('com.report.professional.text033'),
            percent: this.aiData?.characterScore?.autonomy,
            comment: this.aiData?.characterScore?.autonomyComment,
          },
        ]
        if (this.reportDim === 1) {
          const arr = ['适应性', '好奇心', '和谐性', '责任心']
          tableList = tableList.filter(item => {
            return arr.includes(item.dimension)
          })
        }

        if (this.reportDim === 0) {
          totalText.push(this.aiData?.characterScore?.adaptComment)
          totalText.push(this.aiData?.characterScore?.ambitionComment)
          totalText.push(this.aiData?.characterScore?.autonomyComment)
          totalText.push(this.aiData?.characterScore?.curiousComment)
          totalText.push(this.aiData?.characterScore?.dutyComment)
          totalText.push(this.aiData?.characterScore?.harmoniousComment)
          totalText.push(this.aiData?.characterScore?.socialComment)
        } else {
          totalText.push(this.aiData?.characterScore?.adaptComment)
          totalText.push(this.aiData?.characterScore?.curiousComment)
          totalText.push(this.aiData?.characterScore?.dutyComment)
          totalText.push(this.aiData?.characterScore?.harmoniousComment)
        }
      }
      return this.showEcharts || (tableList && tableList.length) || (totalText && totalText.length)
    },
    // meritasHasData() {
    //   //MeriTas测评(meritas)有数据
    // },
    xljkHasData() {
      //心理健康(text012)有数据
      return this.aiData && this.aiData.mentalScore
    },
    dmksHasData() {
      //代码考试(text013)有数据
      const arr = this.answerResult.filter(item => {
        const info = Array.isArray(item) ? item[0] : item
        return [7].includes(+info.question?.problem_answer_method)
      })
      return arr && arr.length > 0
    },
    fzbHasData() {
      //防作弊记录 有数据
      let multipleImageList = [
        {
          // 电脑监控
          label: this.$t('com.report.antiCheating.computerMonitoring'),
          list: this.provideObj.antiCheatingImages?.fullRecordingImages || [],
          show: Boolean(this.provideObj.antiCheatingInfo?.fullRecordingFlag),
        },
        {
          // 手机监控
          label: this.$t('com.report.antiCheating.phoneMonitoring'),
          list: this.provideObj.antiCheatingImages?.mobileMonitorImages || [],
          show: Boolean(this.provideObj.antiCheatingInfo?.phoneMonitoring),
        },
        {
          // 屏幕监控
          label: this.$t('com.report.antiCheating.screenRecording'),
          list: this.provideObj.antiCheatingImages?.screenRecordingImages || [],
          show: Boolean(this.provideObj.antiCheatingInfo?.isFullrecording),
        },
      ]
      return (
        (this.interviewInfos?.idCard?.frontIdCard && this.interviewInfos.idCard?.backIdCard) ||
        this.interviewInfos?.fullRecords?.length ||
        this.interviewInfos?.answerResult?.length ||
        this.interviewInfos?.cameraMonitorList?.length ||
        this.recordData?.cheatLogs?.length > 0 ||
        this.interviewInfos?.interviewResult?.mobileMonitorVideoList?.length ||
        (this.provideObj.antiCheatingInfo?.abnormalDetection === 1 && this.recordData?.usedTimes > 0) ||
        this.provideObj.antiCheatingInfo?.checkTextRepetition === 1 ||
        this.provideObj.antiCheatingInfo?.checkPhotoDetection === 1 ||
        this.provideObj?.antiCheatingLogs?.length ||
        multipleImageList.map(item => item.list).flat()?.length
      )
    },
    isFrom() {
      let isFrom = this.$route.query?.isFrom
      if (isFrom && isFrom === 'miniapp') return 'miniapp'
      else return ''
    },
    // isL2ScoreStop(){
    //   return this.interviewInfos.isL2ScoreStop !=1
    // }
  },
  methods: {
    initActab() {
      this.$nextTick(() => {
        setTimeout(() => {
          const elms = document.querySelectorAll('li[id^="tab-"]')
          console.log('显示的标签页元素集合:', elms)
          let tabIndex = 0
          if (elms && elms.length > 1) {
            const ids = elms[0].id
            if (ids && ids.indexOf('tab-') === 0) {
              const cur = ids.split('-')
              if (!isNaN(Number(cur[1]))) {
                console.log('el-tabs第一个标签页的name值:', Number(cur[1]))
                tabIndex = Number(cur[1])
                if (this.isFinished && this.hasVideoQues?.length) {
                  console.log('结束面试并且有视频题')
                  tabIndex = 1
                }
              }
            }
          } else {
            tabIndex = 6
          }
          if (this.$route.path === '/shareurl' && this.$route.query?.from === 'interviewPlan' && this.permissionsTreeId.includes('planInterview')) {
            tabIndex = 3
          }
          if (!this.isDwdTest) this.tabIndex = tabIndex
          this.imgLoading = false
        }, 800)
      })
    },
    getUserInfo() {
      getUserInfo()
        .then(res => {
          if (res && res.code === 0) {
            const { authRoleId, companyId } = res?.data || {}
            this.getUserPermissionInfo(authRoleId, companyId) // 获取用户权限信息
            this.aiMarkConfig(res.data?.companyId)
          } else {
            this.$store.commit('setUserInfo', null)
            this.$store.commit('setToken', '')
            localStorage.removeItem('token')
          }
        })
        .catch(err => {
          if (err === 'Error, token is expired') {
            this.$store.commit('setUserInfo', null)
            this.$store.commit('setToken', '')
            localStorage.removeItem('token')
          }
        })
    },
    //获取用户AI标注设置配置
    aiMarkConfig(comid) {
      getAiInterLabelConfig({ companyId: comid }).then(res => {
        if (res && res.code === 0) {
          let aicfg = {
            allStatus: res.data.allStatus === 1 ? true : false, //AI总分展示状态
            singleStatus: res.data.singleStatus === 1 ? true : false, //AI单题分数展示状态
            manualStatus: res.data.manualStatus === 1 ? true : false, //人工标注状态
            aiScoreStatus: res.data.aiScoreStatus === 1 ? true : false, //人工标注后，ai单题展示状态
          }
          this.$store.commit('SET_AICFG', aicfg)
        }
      })
    },
    async getUserPermissionInfo(authRoleId, companyId) {
      const res = await getUserPermissions({ authRoleId, companyId }).catch(err => err)
      const { code, data: { authPages, authButtons } = { authPages: [], authButtons: [] } } = res
      if (code === 0) {
        // const permissions = (authButtons || []).map(p => p.code) // 将code提取出来
        const pagePermissions = (authPages || []).map(p => p.code) // 将code提取出来
        console.log(pagePermissions)
        // 构造按钮权限的树状结构
        const parentList = (authButtons || []).filter(item => item.parentCode === '0')
        parentList.forEach(p => {
          if (!p.children) p.children = []
          p.children = (authButtons || []).filter(item => item.parentCode === p.code)
        })
        this.permissionsTreeId = parentList.map(e => e.code)
        console.log('登录permissionsTreeId--', this.permissionsTreeId)
        this.setBrfore()
      }
    },
    getAiTabsConfig(cpid) {
      //tabType:1简历信息 2AI视频面试 3AI面试tab组合(inUse 1:展示包含的所有tab 0:仅展示包含的有数据的tab) 4第三方测评 5 面试信息 6,录用，7背调 8备注 9操作记录
      if (!cpid) return
      let tymapid = {
        1: 'reportResume',
        2: 'reportAIInterview',
        4: 'thirdComment',
        5: 'planInterview',
        6: 'offer',
        7: 'background',
        8: 'reportRemark',
        9: 'reportInterviewLog',
      }
      getResumeLabelConfig({ companyId: cpid }).then(res => {
        if (res && res.code === 0) {
          this.$store.commit('SET_ReportTabConfig', res.data || [])
          if (res.data?.length) {
            res.data.forEach(e => {
              if (!this.token) {
                if (e.inUse && e.tabType !== 3) {
                  this.permissionsTreeId.push(tymapid[e.tabType])
                }
              }
              if (e.tabType === 3) {
                this.showAllAiTab = e.inUse ? true : false
              }
              if (e.tabType === 10) {
                this.showAllScore = e.inUse ? true : false
              }
            })
            console.log('未登录permissionsTreeId--', this.permissionsTreeId)
          }
        }
      })
    },
    //获取报告配置
    getSysCfgList(comid) {
      getSystemConfigList(comid).then(res => {
        if (res && res.code === 0) {
          this.$store.commit('SET_AiReportCfg', res.data)
        }
      })
    },
    getTabPerm(ivd, jcd) {
      if (!ivd) return
      getMeritasReport({ interviewId: ivd })
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            this.meritasHasData = Boolean(data)
          } else {
            this.meritasHasData = false
          }
        })
        .catch(() => {
          this.meritasHasData = false
        })
      if (jcd) {
        getCheatLogs({
          jobseekerChannelId: jcd,
          interviewId: ivd,
        }).then(res => {
          if (res.code === 0) {
            this.recordData = res.data
          }
        })
      }
    },
    onSelectLang(opt = {}) {
      console.log(opt, this)
      const { lang } = opt
      this.$store.commit('setLanguage', lang)
      location.reload()
    },
    onConfirm(value) {
      this.currentInterviewInfo = value
      this.$store.commit('setInterviewId', value.interviewId)
      // const jobseekerChannelId = value.jobSeekerChannelId
      this.showInterview = false
      this.getAIReportData()
      this.interviewInfo()
    },
    handleClick() {
      this.showInterview = true
    },
    onOpenPreviewer(url) {
      this.currentPreviewUrl = url
      this.showPreviewer = true
    },
    call(phone) {
      if (this.isMobile) {
        location.href = 'tel:' + phone
      }
    },
    closeRecordDialog() {
      this.setBrfore()
      this.showEstimateRecordDialog = false
    },
    estimateClose() {
      this.showEstimateDialog = false
      this.getEstimateGroupByTimePeriod()
    },
    upCandidate() {
      console.log(this.shortList)
      if (this.shortList && this.shortList.length) {
        let currentIndex = this.shortList.findIndex(item => {
          return item.jobSeekerChannelId === this.jobseekerChannelId
        })

        if (currentIndex > 0) {
          let nextItem = this.shortList[currentIndex - 1]
          //this.$store.dispatch('requestShortList')
          this.$store.commit('clearData')
          let redirectUrl = ''
          if (this.recommendStageId) {
            redirectUrl = `/shareurl?jobseekerChannelId=${nextItem.jobSeekerChannelId}&operateStatus=${nextItem.operateStatus}&type=1&recommendStageId=${nextItem.stageId}&stageTypeKind=${nextItem.stageTypeKind}`
          } else {
            redirectUrl = `/shareurl?jobseekerChannelId=${nextItem.jobSeekerChannelId}&type=1&activeIndex=3`
          }
          if (this.$route.path === '/shareurl' && this.$route.query?.from === 'interviewPlan') {
            redirectUrl += '&from=interviewPlan'
          }
          this.$router.replace(`/redirect?url=${encodeURIComponent(redirectUrl)}`)
        } else {
          this.$toast({ type: 'fail', message: this.$t('com.report.base.text064') })
        }
      }
    },
    downCandidate() {
      if (this.shortList && this.shortList.length) {
        let currentIndex = this.shortList.findIndex(item => {
          return item.jobSeekerChannelId === this.jobseekerChannelId
        })

        if (currentIndex < this.shortList.length - 1) {
          let nextItem = this.shortList[currentIndex + 1]
          //this.$store.dispatch('requestShortList')
          this.$store.commit('clearData')
          let redirectUrl = ''
          if (this.recommendStageId) {
            redirectUrl = `/shareurl?jobseekerChannelId=${nextItem.jobSeekerChannelId}&operateStatus=${nextItem.operateStatus}&type=1&recommendStageId=${nextItem.stageId}&stageTypeKind=${nextItem.stageTypeKind}`
          } else {
            redirectUrl = `/shareurl?jobseekerChannelId=${nextItem.jobSeekerChannelId}&type=1&activeIndex=3`
          }
          if (this.$route.path === '/shareurl' && this.$route.query?.from === 'interviewPlan') {
            redirectUrl += '&from=interviewPlan'
          }
          this.$router.replace(`/redirect?url=${encodeURIComponent(redirectUrl)}`)
        } else {
          this.$toast({ type: 'fail', message: this.$t('com.report.base.text065') })
        }
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    removeCanidateForm() {
      this.$nextTick(() => {
        let index = this.resumeTab.findIndex(item => item.ref === 'candidateForm')
        const arr = this.resumeTab.filter(i => {
          return i
        })
        console.log('removeCanidateFormremoveCanidateForm', this.resumeTab, arr, index)
        if (index > -1) {
          this.resumeTab.splice(index, 1)
        }
      })
    },
    initResumeTab() {
      this.resumeTab = []
      if (this.$route.query.resume) {
        this.resumeIndex = parseInt(this.$route.query.resume)
      }
      if (this.interviewResult.resumeUrl && this.resumeIndex !== 2) {
        this.resumeTab.push({
          text: this.$t('com.report.base.text066'),
          ref: 'originResume',
        })
      }

      if (this.resumeIndex !== 1) {
        this.resumeTab.push({
          text: this.$t('com.report.base.text067'),
          ref: 'standardResume',
        })
      }

      if (this.positionId && this.jobseekerChannelId && this.userInfo && this.userInfo.userId) {
        this.resumeTab.push({
          text: this.$t('com.report.base.text068'),
          ref: 'candidateForm',
        })
      }
      this.tabItem = this.resumeTab[0]
    },
    getAiNoticeStatus() {
      getAiNoticeStatus({ interviewId: this.interviewId }).then(res => {
        if (res && res.code === 0) {
          if (res.data === 0) {
            this.inviteAIInterviewFlag = false
          }
        }
      })
    },
    confirmRefuse() {
      //如果是评价简历
      if (this.estimateIndex === 1) {
        this.setOperateStatus(2)
      } else if (this.estimateIndex === 2) {
        this.setInterviewerReceiveStatus(2)
      }
    },
    requestInterviewerReceiveStatus() {
      getInterviewerReceiveStatus({ interviewPlanId: this.interviewPlanId }).then(res => {
        if (res && res.code === 0) {
          if (res.data.status === 0) {
            this.estimateIndex = 2
          }
        }
      })
    },
    setInterviewerReceiveStatus(index) {
      interviewerReceiveStatus({
        interviewerReceiveStatus: index, //1-接受 2-拒绝
        interviewPlanId: this.interviewPlanId,
      }).then(res => {
        if (res && res.code === 0) {
          this.$notify({ type: 'success', message: this.$t('com.report.base.text069') })
          if (index === 1) {
            this.showRefuseDialog = false
          }
          this.estimateIndex = 0
        } else {
          this.$notify({ type: 'danger', message: this.$t('com.report.base.text070') })
        }
      })
    },
    initData() {
      // if (this.$route.query.stageId) {
      //   this.stageId = this.$route.query.stageId
      // }

      this.urlType = this.$route.query.urlType || ''

      if (this.$route.query.interviewPlanId) {
        this.$store.commit('setInterviewPlanId', this.$route.query.interviewPlanId)
        this.requestInterviewerReceiveStatus()
      }

      if (this.$route.query.activeIndex) {
        this.tabIndex = parseInt(this.$route.query.activeIndex)
      }

      if (this.$route.query.showInterviewPlan) {
        this.tabIndex = 3
      }
      if (this.$route.query.userid && !this.interviewId) {
        this.$store.commit('setInterviewId', this.$route.query.userid)
      }
      if (this.$route.query.type) {
        this.$store.commit('setEstimateType', parseInt(this.$route.query.type))
        if (this.$route.query.type === '1') {
          this.$store.commit('setJobseekerChannelId', this.$route.query.userid)
        }
      }

      if (this.$route.query.operateStatus && this.$route.query.operateStatus) {
        if (!this.shortList || !this.shortList.length) {
          this.$store.dispatch('requestShortList')
        }
        this.getOperateStatus()
      }

      if (this.$route.query.overtime) {
        const time = new Date().getTime()
        if (time > +this.$route.query.overtime) {
          this.$toast({ type: 'warning', message: this.$t('com.report.base.text071') })
          return
        }
      }

      this.findId()
    },
    getOperateStatus() {
      jobSeekerChannelDetailStatus({
        jobSeekerChannelId: this.jobseekerChannelId,
        interviewerId: this.userInfo.userId,
        recommendStageId: this.recommendStageId,
      }).then(res => {
        if (res && res.code === 0) {
          //recommendStatus（0-待筛选 1-已筛选 3.已失效）
          if (res.data.recommendStatus === 0) {
            this.estimateIndex = 1
          } else if (res.data.recommendStatus === 1) {
            this.interviewerOperateStatus = res.data.operateStatus
          }
        }
      })
    },
    goRefuse() {
      this.showRefuseDialog = true
    },
    // choseOperateStatus(index) {
    //   this.choseOperateStatusIndex = index
    //   this.showEstimateDialog = true
    //   this.setOperateStatus(index)
    // },

    //设置简历筛选的结果
    setOperateStatus(index) {
      jobSeekerChannelOperate({
        estimateContent: '',
        interviewerId: this.userInfo.userId,
        jobSeekerChannelId: this.jobseekerChannelId,
        operateChannel: index, //1.通过 2.拒绝
        stageId: this.recommendStageId,
        positionId: this.positionId,
      }).then(res => {
        if (res && res.code === 0) {
          this.$notify({ type: 'success', message: this.$t('com.report.base.text069') })
          this.showEstimateDialog = false
          this.getOperateStatus()
          this.$store.dispatch('requestShortList')
          const isWechatHint = localStorage.getItem('isWechatHint')
          if (isWechatHint === '0') {
            //0不在提示
            console.log(isWechatHint)
          } else if (index === 1 && this.isWechatHint === 1) {
            // this.showDatePicker = true
          }
          this.getEstimateGroupByTimePeriod()
        } else {
          this.$notify({ type: 'danger', message: this.$t('com.report.base.text070') })
        }
      })
    },

    openEstimateType(index) {
      if (!this.isShowAiVideo) {
        this.$store.commit('setEstimateType', 10)
      }
      this.operateChannel = index
      this.showEstimateDialog = true
    },
    cancleToDatePicker() {
      this.showDatePicker = false
      if (this.noMoreTip) {
        this.saveNoMoreTip()
      }
    },
    saveNoMoreTip() {
      console.log(this.noMoreTip)
      updateWechatHint({
        isWechatHint: 0, //0 不在提示
      }).then(res => {
        if (res && res.code === 0) {
          console.log('sucess')
          // this.getAuth()
          localStorage.setItem('isWechatHint', '0')
        }
      })
    },

    toDatePicker() {
      this.showDatePicker = false
      this.$router.push('/datePicker')
    },
    /**
     * 转发候选人
     */
    transmitResume(params) {
      console.log('params', params)
      const registerUserIdAry = params.selectedList.map(item => {
        return item.id
      })
      const data = {
        registerUserIdAry: registerUserIdAry,
        resumeIndex: 0,
        selectNote: params.remarkContent,
        targetStageId: this.recommendStageId,
        targetStageTypeKind: +this.stageTypeKind,
        updateIdsAry: [this.jobseekerChannelId],
      }
      console.log(data)
      transmitResume(data).then(res => {
        if (res && res.code === 0) {
          this.showEmailDialog = false
          this.showList = false
          this.$toast({ type: 'success', message: this.$t('com.report.base.text072') })
        }
      })
    },

    //分享报告
    shareByEmail(item) {
      let data = {
        email: item.email,
        shareUrl: `${process.env.VUE_APP_API_ROOT || 'http://m.aidexianzhaopinguan.com'}/newShareReport?userid=${this.jobseekerChannelId}`, //
        // shareUrl: `${host || 'http://m.aidexianzhaopinguan.com'}/newShareReport?jobseekerChannelId=${this.jobseekerChannelId}`, //
        companyId: this.companyId,
        interviewId: this.interviewId,
      }
      shareByEmail(data).then(res => {
        if (res.code === 0) {
          this.showEmailDialog = false
          this.showList = false
          this.$toast({ type: 'success', message: this.$t('com.report.base.text073') })
        }
      })
    },
    validateEmail() {
      if (!this.email) {
        this.$toast({ type: 'fail', message: this.$t('com.report.base.text054') })
        return false
      }
      let myreg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      let bool = myreg.test(this.email)
      if (!bool) {
        this.$toast({ type: 'fail', message: this.$t('com.report.base.text074') })
        return false
      } else {
        this.shareByEmail()
      }
      return true
    },
    updateScrollTop(scrollTop) {
      this.$refs.reportContent.scrollTop = scrollTop
    },
    updateItemScrollHeight() {
      this.tabBarHeight = this.$refs.tabBar.scrollHeight
      this.baseInfoHeight = this.$refs.baseInfo.scrollHeight
    },
    updateTabIndex(index) {
      if (index === 1 && this.estimateType === 1) {
        if (!(this.answerResult && this.answerResult.length)) {
          this.$toast({ type: '', message: this.$t('com.report.base.text075') })
          return
        }
      }
      if (index === 2 && this.estimateType === 1) {
        this.$toast({ type: '', message: this.$t('com.report.base.text076') })
        return
      }
      this.tabIndex = index
    },
    initAITab() {
      // 报告总结
      // 视频面试
      // 自定义试题
      // 认知能力
      // 职业性格
      // 模拟编程
      //1报告总结
      this.aiTabArray.push({
        text: this.$t('com.report.base.text077'),
        ref: 'AISummary',
      })
      //2视频面试
      let videoProblemArray = this.answerResult.filter(item => {
        const info = Array.isArray(item) ? item[0] : item
        return info.question.problem_answer_method === 1 || info.question.problem_answer_method === 6
      })
      if (videoProblemArray && videoProblemArray.length) {
        this.aiTabArray.push({
          text: this.$t('com.report.base.text078'),
          ref: 'AIVideoReport',
        })
      }
      //3自定义试题
      let customizeQuestion = this.answerResult.filter(item => {
        const info = Array.isArray(item) ? item[0] : item
        return info.isCustom === 1
      })
      if (customizeQuestion && customizeQuestion.length) {
        this.aiTabArray.push({
          text: this.$t('com.report.base.text079'),
          ref: 'CustomizeQuestion',
        })
      }
      //4认知能力
      if (this.aiData && this.aiData.realizeScore) {
        let keys = Object.keys(this.aiData.realizeScore)
        if (keys && keys.length) {
          this.aiTabArray.push({
            text: this.$t('com.report.base.text010'),
            ref: 'CognitiveAbility',
          })
        }
      }
      //5职业性格
      if (this.aiData.characterScore) {
        let arr = Object.keys(this.aiData.characterScore)

        if (arr.length) {
          this.aiTabArray.push({
            text: this.$t('com.report.base.text011'),
            ref: 'OccupationalCharacter',
          })
        }
      }
      //6.模拟编程
      let codeQuestionArray = this.answerResult.filter(item => {
        const info = Array.isArray(item) ? item[0] : item
        return info.question.problem_answer_method === 7
      })
      if (codeQuestionArray && codeQuestionArray.length) {
        this.aiTabArray.push({
          text: this.$t('com.report.base.text080'),
          ref: 'ImitatePrograme',
        })
      }
    },
    jobseekerResumeInfo() {
      jobseekerResumeInfo({ jobseekerChannelId: this.jobseekerChannelId }).then(res => {
        if (res && res.code === 0) {
          this.resumeInfo = res.data
        }
      })
    },
    scrollUpdateShowActiveIndex(index) {
      console.log(index)
      // this.activeIndex = index
    },
    shareToFriends() {
      // const host = process.env.NODE_ENV === 'production' ? 'https://airczp.cn/m' : window.location.origin + '/m'
      // if (dd.env.platform !== 'notInDingTalk' && this.corpId) {
      //   this.shareUrl = `${host}/shareurl?userid=${this.interviewId}&type=${this.estimateType}`
      //   if (this.userInfo.userId) {
      //     this.getJsApiTicket()
      //   } else if (!this.userInfo) {
      //     this.$toast({ type: '', message: '无此权限' })
      //     return false
      //   }
      //   return
      // } else {
      //   //this.showEmailDialog = true
      //   this.shareUrl = `${host}/sharereport?userid=${this.interviewId}&corpId=${this.corpId}&type=${this.estimateType}`
      //   if (this.jobseekerChannelId) {
      //     this.shareUrl = this.shareUrl + `&jobseekerChannelId=${this.jobseekerChannelId}`
      //   }
      // }
    },
    goManualEstimate() {
      this.$router.push('/manualEstimateDialog')
      //this.$store.commit('setShowManualEstimateDialog', true)
      // if (this.estimateType === 2 || this.source === 3) {
      //   this.showManualEstimateDialog = true
      // }
    },
    getAIReportData() {
      report4MobileV2({ interviewId: this.interviewId, jobSeekerChannelId: this.jobseekerChannelId }).then(res => {
        if (res && res.code === 0) {
          this.aiDataFlag = true
          this.$store.commit('setAIData', res.data)
        }
      })
    },
    updateShowActiveIndex(index) {
      this.showActiveIndex = index
    },
    updatedActiveIndex(index, tabItem) {
      this.updateItemScrollHeight()
      if (tabItem) {
        this.tabItem = tabItem
      }
      this.$nextTick(() => {
        EventBus.$emit('updateActiveIndex', index)
      })

      this.activeIndex = index
      //this.showActiveIndex = index
    },
    parseResumeUrl() {
      // const url = this.interviewResult.resumeUrl
      this.resumeUrlLoading = true
      getResumeUrlList({ jobseekerChannelId: this.jobseekerChannelId }).then(res => {
        this.resumeUrlLoading = false
        const arrUrl = res.data || []
        const url = arrUrl.filter(i => {
          return i.isLatest === 1
        })[0]?.resumeUrl
        const ext = url.substr(url.lastIndexOf('.')).toLowerCase()
        const extList = ['.png', '.jpg', '.jpeg']
        if (!extList.includes(ext)) {
          let type = ''
          if (ext === '.doc' || ext === '.docx' || ext === '.pdf') type = 'doc2html'
          if (ext === '.mht' || ext === '.mhtml') type = 'mht2html'
          if (ext === '.html' || ext === '.htm') type = 'html2pdf'
          //如果是猎聘的html文件，那么就不走预览系统
          if (type === 'html2pdf' && this.interviewResult.channelId === 5) {
            this.$store.commit('setPreviewResumeUrl', url)
          } else {
            this.convertFile(url, type)
          }
        } else {
          //this.previewResumeUrl = url
          this.$store.commit('setPreviewResumeUrl', url)
        }
      })
    },
    convertFile(url, type) {
      convertFile({
        sourceUrl: url,
        type,
      }).then(res => {
        if (res && res.code === 0) {
          this.$store.commit('setPreviewResumeUrl', res.data)
          //this.previewResumeUrl = res.data
        }
      })
    },
    jobseekerChannelInfo(callback) {
      const data = {
        id: this.interviewId || this.$route.query.jobseekerChannelId,
        jobseekerChannelId: this.$route.query.jobseekerChannelId,
      }
      jobseekerChannelInfo(data).then(res => {
        if (res && res.code === 0) {
          if (+res.data?.transferWorkSyncAiData === 1 && res.data.interviewId) {
            this.transferWorkSyncAiData = +res.data?.transferWorkSyncAiData
            // this.interviewId = res.data.interviewId
            this.$store.commit('setInterviewId', res.data.interviewId)
            this.getAIReportData()
            this.interviewInfo()
            return false
          }
          this.imgLoading = false
          this.$store.commit('setInterviewResult', { ...res.data, jobseekerChannelId: this.$route.query.jobseekerChannelId })
          this.$store.commit('setPositionId', (res.data && res.data.positionId) || '')
          this.$store.dispatch('queryInterviewPlan')
          if (res.data.resumeUrl) {
            this.parseResumeUrl()
          }
          this.initResumeTab()
          this.getPersonWorkEtc()
          //this.baseInfo = res.data
          if (callback) {
            callback(res.data)
          }
          this.$store.commit('setEstimateType', 1)
          this.getInterviewInfos()
        }
      })
    },

    findId() {
      findId({ userId: this.interviewId, jobseekerChannelId: this.$route.query.jobseekerChannelId }).then(res => {
        if (res && res.code === 0) {
          if (res.data) {
            this.getAiTabsConfig(res.data.company_id)
            this.aiMarkConfig(res.data.company_id)
            this.$store.commit('setCompanyId', (res.data && res.data.company_id) || '')
            this.$store.commit('setPositionId', (res.data && res.data.positionid) || '')
            if (this.isFrom === 'miniapp') {
              document.title = 'AI猫渴模拟面试'
              if (this.$route.query?.userid) {
                this.$store.commit('setInterviewId', this.$route.query?.userid)
              } else {
                this.$store.commit('setInterviewId', res.data.interviewId)
              }
              this.isDwdTest = true
              let pid = (res.data && res.data.positionid) || ''
              if (pid === '3366ef075c7d4ea2877fe731524b20ae' || pid === '2eee30418188462784cb959e006cd22a') this.updateTabIndex(18)
              else if (pid === 'd2fc692c7273431d8a8a2b8c2f096036' || pid === '1cc55591f1244eb5ae7a8388c51eb7bb') this.updateTabIndex(19)
              else if (pid === '75991be200d04d93b9cae2d18639982c' || pid === 'f0d900b59ad74ac587e1aed98d211a42') this.updateTabIndex(20)
              else if (pid === 'ef6edcccd47f4bf2ac07f4f91e97cb11' || pid === '0e05212b95aa4bec9e993364a40e1db1') this.updateTabIndex(21)
              else this.isDwdTest = false
            } else {
              if (res.data.interviewId) {
                this.$store.commit('setInterviewId', res.data.interviewId)
              }
            }

            this.$store.commit('setJobseekerChannelId', res.data && res.data.jobseekerChannelId)
            this.currentPerson = res.data || {}
            this.$store.commit('setShowAiVideo', res.data.isShowAiVideo)
            this.getEstimateGroupByTimePeriod()

            this.$store.dispatch('queryInterviewPlan')
            // this.getPersonWorkEtc()
            if (+res.data.stageId === 1) {
              this.$store.commit('setJobseekerChannelId', this.$route.query.jobseekerChannelId)
              this.jobseekerChannelInfo()
            } else {
              this.getAIReportData()
              this.interviewInfo()
            }
            this.getAntiCheating() // 获取防作弊记录信息
            this.getAntiCheatingLogs() // 获取防作弊日志信息
            this.getAntiCheatingImages() // 获取防作弊监控照片
          }
        }
      })
    },

    getPersonWorkEtc() {
      this.requestFindAllEducation()
      this.requestFindAllWorkInfo()
      this.requestGetProjectExperience()
    },

    shareReport() {
      var data = {
        shareUrl: this.shareUrl,
        corpId: this.corpId,
        currentUserId: this.userInfo.userId,
        appId: this.appId,
        cid: this.cid,
        interviewId: this.interviewId,
      }

      //初筛状态
      data = {
        ...data,
        jobseekerChannelId: this.jobseekerChannelId,
      }
      sendMsg(data).then(res => {
        if (res && res.code === 0) {
          this.$toast({ type: 'info', message: res.msg })
        } else if (res && res.msg) {
          this.$toast({ type: 'info', message: res.msg })
        }
      })
    },

    interviewInfo() {
      this.getAiNoticeStatus()
      const data = {
        userId: this.interviewId,
        companyId: this.companyId,
        positionId: this.positionId,
        jobSeekerChannelId: this.jobseekerChannelId,
      }
      interviewInfo(data).then(res => {
        if (res.code === 0 && res.data) {
          // this.imgLoading = false
          this.baseInfo = res.data.interviewResult
          this.meritasShow = res.data.meritasSwitch // meritas开关信息
          this.isL2ScoreStop = res.data.isL2ScoreStop !=1
          
          this.$store.commit('setInterviewMsg', res.data)
          this.$store.commit('setInterviewResult', { ...res.data.interviewResult, transferWorkSyncAiData: this.transferWorkSyncAiData })
          this.$store.commit('setAnswerResult', res.data.answerResult)
          this.$store.commit('setJobseekerChannelId', res.data.interviewResult.jobseekerChannelId)
          // 需要传给后代组件的数据
          this.provideObj.idCard = res.data.idCard || {} // 身份证信息
          this.provideObj.fullRecords = res.data.fullRecords || [] // 屏幕录制视频
          this.provideObj.cameraMonitorList = res.data.cameraMonitorList || [] // 新数据，全程录制视频放在 cameraMonitorList 字段里面
          // 旧数据，过滤掉视频题和口音题，剩下的有 video_url 字段的是全程录制的视频
          this.provideObj.questionVideoList = res.data.answerResult
            .filter(item => {
              const info = Array.isArray(item) ? item[0] : item
              return info?.video_url?.length && ![1, 6].includes(info?.question?.problem_answer_method)
            })
            .map(item => {
              const info = Array.isArray(item) ? item[0] : item
              return info.video_url
            })

          if (res.data.interviewResult.interview_status === 'finish' || this.transferWorkSyncAiData === 1) {
            //如果
            if (!this.tabIndex) {
              this.tabIndex = 1
            }
            this.$store.commit('setEstimateType', 2)
          } else {
            this.$store.commit('setEstimateType', 1)
          }

          this.interviewResultFlag = true
          if (this.interviewResult.resumeUrl) {
            this.parseResumeUrl()
          }
          this.initResumeTab()
          this.getInterviewInfos()
        }
      })
    },
    getInterviewInfos() {
      getInterviewInfos({
        jobSeekerChannelId: this.jobseekerChannelId,
      }).then(res => {
        if (res && res.code === 0) {
          const data = res.data || []
          this.currentInterviewInfo = data.find(item => {
            return item.interviewId === this.interviewId
          })

          const i = this.interviewInfos.findIndex(item => {
            return item.interviewId === this.interviewId
          })
          this.defaultIndex = i >= 0 ? i : 0

          this.$store.commit('setInterviewInfos', data)
        } else {
          this.$store.commit('setInterviewInfos', [])
        }
      })
    },
    // 教育经历
    requestFindAllEducation() {
      findAllEducation({ jobseekerChannelId: this.jobseekerChannelId }).then(res => {
        if (res && res.data && Array.isArray(res.data)) {
          this.$store.commit('setEducation', res.data)
        }
      })
    },
    // 工作经历
    requestFindAllWorkInfo() {
      findAllWorkInfo({ jobseekerChannelId: this.jobseekerChannelId }).then(res => {
        if (res && res.data && Array.isArray(res.data)) {
          this.$store.commit('setWorkInfo', res.data)
        }
      })
    },
    //项目经历
    requestGetProjectExperience() {
      getProjectExperience({ jobseekerChannelId: this.jobseekerChannelId }).then(res => {
        if (res && res.data) {
          this.$store.commit('setProjectInfo', res.data)
        }
      })
    },
    scrollEvent() {
      this.$nextTick(() => {
        this.baseInfoHeight = this.$refs.baseInfo.scrollHeight
        this.scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      })
    },
    setBrfore() {
      this.$nextTick(() => {
        const len = this.remarkList && this.remarkList.length
        // const len1 = 11 || (this.allEstmate && this.allEstmate.length)
        const ele1 = document.getElementById('mark_label_1')
        const ele2 = document.getElementById('mark_label_2')
        const ele3 = document.getElementById('allEstimateList')
        if (len > 0) {
          ele1 && ele1.setAttribute('data-num', len > 99 ? '(99+)' : '(' + this.remarkList.length + ')')
          ele2 && ele2.setAttribute('data-num', len > 99 ? '(99+)' : '(' + this.remarkList.length + ')')

          console.log(ele1, ele2, 'ssssssssss')
        }
        console.log('hidden-data-num', ele3)
        if (this.periodLength !== 0 && ele3) {
          ele3.classList.remove('hidden-data-num')
          ele3.setAttribute('data-num', this.periodLength > 99 ? '99+' : this.periodLength)
        } else {
          ele3 && ele3.classList.add('hidden-data-num')
        }
        this.$forceUpdate()
      })
    },
    showEstimateDialogClick() {
      this.showEstimateRecordDialog = true
      const param = { jobseekerChannelId: this.jobseekerChannelId, interviewId: this.interviewId, startTime: this.periodTime, interviewerId: this.userInfo.userId }
      clickEstimate(param).then(res => {
        if (res.code === 0) {
          this.periodLength = 0
          const ele3 = document.getElementById('allEstimateList')
          ele3 && ele3.classList.add('hidden-data-num')
        } else {
          this.$toast({ type: 'error', message: res.msg })
        }
      })
    },
    getEstimateGroupByTimePeriod() {
      if (this.jobseekerChannelId && this.interviewId) {
        const param = { jobseekerChannelId: this.jobseekerChannelId, interviewId: this.interviewId, interviewerId: this.userInfo?.userId }
        selectEstimateGroupByTimePeriod(param).then(res => {
          if (res.code === 0) {
            this.periodTime = res.msg
            if (res.data !== null && res.data !== undefined && res.data.length !== 0) {
              this.periodLength = res.data.length
              this.setBrfore()
            } else {
              this.periodLength = 0
            }
          } else {
            this.$toast({ type: 'error', message: res.msg })
          }
        })
      }
    },
    listEstimate() {
      var params = {}

      params = {
        jobseekerChannelId: this.jobseekerChannelId,
      }

      params.interviewId = this.interviewId
      params.estimateMode = 8

      selectAllEstimate(params).then(res => {
        if (res && res.code === 0) {
          const remarkList = (res.data || []).filter(item => {
            return item.estimateType !== 1 && item.estimateType !== 2 && item.estimateType !== 3
          })
          this.$store.commit('setRemarkList', remarkList)
        }
      })
    },
    getAllEstimate() {
      let data = {
        jobseekerChannelId: this.jobseekerChannelId,
        interviewId: this.interviewId,
      }
      const companyId = this.$store.state.userInfo.companyId
      const isZsCompany = this.zsCompanyId.includes(companyId)
      console.log('======================', this.$store.state.userInfo.companyId)
      if (isZsCompany) {
        data = {
          ...data,
          recommendStageId: this.recommendStageId,
        }
        //获取招商推荐记录
        selectAllEstimateGroupByforCMBC(data).then(res => {
          if (res && res.code === 0) {
            this.allEstimate = res.data
            this.$store.commit('setAllEstmate', this.allEstimate)
          }
        })
      } else {
        selectAllEstimateGroupBy(data).then(res => {
          if (res && res.code === 0) {
            this.allEstimate = res.data
            this.$store.commit('setAllEstmate', this.allEstimate)
          }
        })
      }
    },
    // 获取防作弊信息
    async getAntiCheating() {
      if (!this.interviewId) return // 如果没有面试id，直接跳出
      const res = await getAntiCheatingInfo({ interviewId: this.interviewId })
      const { code, msg, data } = res
      if (code === 0) {
        this.provideObj.antiCheatingInfo = data
      } else {
        this.$toast({ type: 'fail', message: msg })
      }
    },

    // 获取防作弊日志
    async getAntiCheatingLogs() {
      if (!this.interviewId) return // 如果没有面试id，直接跳出
      const res = await getAntiCheatingLogs({ interviewId: this.interviewId })
      const { code, msg, data } = res
      if (code === 0) {
        this.provideObj.antiCheatingLogs = data
      } else {
        this.$toast({ type: 'fail', message: msg })
      }
    },

    // 获取防作弊监控照片（电脑监控、手机监控、屏幕监控）
    async getAntiCheatingImages() {
      if (!this.interviewId) return // 如果没有面试id，直接跳出
      const res = await getAntiCheatingImages({ interviewId: this.interviewId })
      const { code, msg, data } = res
      if (code === 0) {
        this.provideObj.antiCheatingImages = data
      } else {
        this.$toast({ type: 'fail', message: msg })
      }
    },
    async getLanguageScore() {
      console.log('this.interviewId11---', this.interviewId)
      if (!this.interviewId) return
      const res = await getLanguageScore({ interviewId: this.interviewId })
      const { code, msg, data } = res
      console.log('data-----', data)
      if (code === 0) {
        this.languageScore = data
      } else {
        this.$toast({ type: 'fail', message: msg })
      }
    },
  },
  filters: {
    F_education(val) {
      return !val ? '' : (educationInfo[val] || {}).label || ''
    },
    filterGender(val) {
      return +val === 0 ? i18n.t('com.report.base.text081') : +val === 1 ? i18n.t('com.report.base.text082') : i18n.t('com.report.base.text083')
    },
  },
}
</script>
<style lang="scss">
div {
  box-sizing: border-box;
}
@font-face {
  font-family: 'iconfont'; /* project id 2013799 */
  src: url('//at.alicdn.com/t/font_2013799_8q8yqs0lakp.eot');
  src: url('//at.alicdn.com/t/font_2013799_8q8yqs0lakp.eot?#iefix') format('embedded-opentype'), url('//at.alicdn.com/t/font_2013799_8q8yqs0lakp.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_2013799_8q8yqs0lakp.woff') format('woff'), url('//at.alicdn.com/t/font_2013799_8q8yqs0lakp.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_2013799_8q8yqs0lakp.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.report-content {
  // width: 100%;
  // height: 100vh;
  // overflow-y: scroll;
  //overflow-x: hidden;
  position: relative;
  .imgLoading {
    display: flex;
    background: #fff !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
  }
  .up-and-down-wrapper {
    width: 48px;
    height: 182px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(120, 120, 120, 0.35);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-wrapper {
      width: 14px;
      font-size: 14px;
      padding: 10px 0px;
      color: #333333;
    }

    .up {
      flex: 1;
      display: flex;
      justify-content: center;
      .text-wrapper {
        display: flex;
        align-items: flex-end;
      }
    }
    .center {
      width: 28px;
      height: 1px;
      background-color: #dddddd;
    }
    .down {
      display: flex;
      justify-content: center;
      flex: 1;
    }
  }

  .screen-tips-container {
    transition: right ease 0.3s;
    position: fixed;
    // width: 112px;
    height: 78px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 6px 0px 0px 6px;
    right: 0;
    top: 140px;
    display: flex;
    z-index: 2;
    .screen-left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      padding: 0 10px;
      img {
        width: 100%;
      }
    }
    .screen-right {
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 15px;
      color: #ffffff;
      padding-right: 5px;
    }
  }

  .screen-tips-container-flod {
    right: -75px;
  }

  .top-row {
    font-size: 18px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #ededed;
    padding: 6px;
    color: #aaaaaa;
  }

  .refuseDialog {
    width: 100%;
    height: 100%;
    .title {
      color: #333333;
      font-size: 0.4rem;
      font-weight: 600;
      padding: 1vh 0 1vh 0.8rem;
    }

    .van-field {
      width: 100%;
      padding: 0 0.8rem;
      margin: 1vh auto 0 auto;
      textarea {
        background: #fafafa;
        border: 0.03rem solid #eaeaea;
      }
    }

    .button-group {
      width: 100%;
      padding: 0 0.8rem;
      margin: 2vh auto 0 auto;
      display: flex;
      justify-content: flex-end;

      div {
        padding: 0.18rem 0.4rem;
        font-size: 0.3rem;
      }

      .cancle {
        border-radius: 0.06rem;
        border: 0.03rem solid #d9d9d9;
        margin-right: 0.6rem;
      }
      .confirm {
        background-color: #1890ff;
        color: #ffffff;
      }
    }
  }
  .estimate-icon {
    position: fixed;
    width: 2rem;
    height: 2rem;
    z-index: 3 !important;
    img {
      width: 100%;
    }
  }

  .share-icon {
    position: fixed;
    z-index: 3 !important;
    width: 2rem;
    height: 2rem;
    img {
      width: 100%;
    }
  }

  .footer2 {
    // height: 116px;
    // min-height: 116px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    // width: 100vw;
    // align-items: center;
    box-sizing: border-box;
    z-index: 3;
    background-color: #ffffff;
    box-shadow: 0px -2px 4px 0px rgba(103, 116, 140, 0.1);
    padding: 9px 15px 11px 15px;
    display: flex;
    justify-content: space-between;
    // margin-bottom: 50px;
    .other-group {
      // width: 60px;
      // width: 100px;
      display: flex;
      // justify-content: space-between;
      font-size: 12px;

      img {
        width: 20px;
      }
      .other-icon-item {
        text-align: center;
        white-space: nowrap;
        height: 40px;
      }
      .other-icon-item + .other-icon-item {
        margin-left: 20px;
      }
    }
    // .other-icon-item + .btn-item {
    //   margin-left: 10px;
    // }
    // .btn-item + .btn-item {
    //   margin-left: 10px;
    // }
    // .estimate-item {
    //   margin-left: 20px;
    // }

    .btn-group {
      // width: 230px;
      margin-left: 10px;
      width: 260px;
      justify-content: space-between;
      display: flex;
      // align-items: center;
      font-size: 15px;
      // padding: 0 10px 0 20px;

      .btn-item {
        width: 80px;
        height: 40px;
        border-radius: 21px;
        text-align: center;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .pass {
        color: #1890ff;
        background: #ffffff;
        border: 1px solid #1890ff;
        //background: #1890ff;
      }

      .pass-active {
        color: #ffffff;
        background: #1890ff;
        border: none;
      }

      .reject {
        color: #f04d4d;
        background: #ffffff;
        border: 1px solid #f04d4d;
      }

      .reject-active {
        color: #ffffff;
        background: #f04d4d;
        border: none;
      }

      .wait {
        border: 1px solid #aaaaaa;
        color: #333333;
      }

      .wait-active {
        color: #ffffff;
        background: #f7b500;
        border: none;
      }

      .disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
    // }
  }

  .footer {
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: -1px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    z-index: 3;

    .van-button {
      width: 50%;
      height: 50px;
      border: none;
      color: #666666;
      font-size: 16px;
      border-radius: 0;
      box-sizing: border-box;
    }
    .blue {
      color: #ffffff;
      background: #1890ff;
    }

    .gray {
      color: #333333;
      background: #efeff4;
    }
  }
  .base-info {
    overflow: hidden;
    position: relative;
    // min-height: 180px;
    // color: #ffffff;
    background: url('../assets/base-info/baseinfo-bg.png') no-repeat;
    background-size: auto;
    padding: 18px 20px 12px 20px;
    border-bottom: 1px solid #dddddd;
    .base-info_base {
      color: #8996bf;
      font-family: PingFangSC-Regular, PingFang SC;
      margin-bottom: 10px;
      .change-lanuage {
        position: absolute;
        right: 12px;
        top: 16px;
        font-size: 14px;
      }
      .avatar-wrapper {
        position: absolute;
        left: 12px;
        top: 15px;
        .avatar {
          height: 68px;
          width: 68px;
          object-fit: cover;
          border-radius: 50%;
        }
        .phone {
          position: absolute;
          bottom: -1px;
          right: 0px;
          height: 26px;
          width: 26px;
        }
      }
      .base_name {
        width: calc(100% - 110px);
        color: #3f4864;
        line-height: 22px;
        font-size: 24px;
        margin-bottom: 5px;
        margin-left: 70px;
      }
      .base_job {
        width: calc(100% - 60px);
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
        margin-left: 72px;
      }
      .base_detail {
        width: calc(100% - 60px);
        margin-left: 70px;
        display: flex;
        font-size: 14px;
        align-items: center;
        .base_detail_item {
          display: flex;
          align-items: center;
          img {
            height: 16px;
            margin-right: 4px;
          }
          .content {
            white-space: nowrap;
          }
        }
        .base_detail_item + .base_detail_item {
          margin-left: 16px;
        }
      }
    }
    .base-info_ai {
      // display: flex;
      color: #8996bf;
      .vip-img {
        // width: 80px;
        // margin: 0 auto;
        // display: flex;
        // align-items: center;
        white-space: nowrap;
        img {
          width: 100%;
          height: 16px;
        }
      }
      .pr-9 {
        font-size: 14px;
        font-weight: 500px;
        padding-right: 4px;
        margin-right: 4px;
        line-height: 14px;
        // width: 80px;
        min-width: 65px;
        // white-space: nowrap;
      }
      .score {
        flex-wrap: wrap;
      }
      .score-star {
        font-size: 14px;
        display: flex;
        line-height: 14px;
        // justify-content: space-between;
        align-items: center;
        // width: 100%;
        // padding: 0 10px;
        // margin-bottom: 10px;
        .pr-9 {
          font-size: 14px;
          // font-weight: 500;
          padding-right: 4px;
          line-height: 14px;
          // width: 80px;
          min-width: 65px;
          // white-space: nowrap;
        }
        .pr-10 {
          font-size: 14px;
          padding-right: 4px;
          line-height: 14px;
          margin-bottom: 4px;
          span {
            white-space: nowrap;
          }
        }

        .divider {
          width: 1px;
          max-width: 1px;
          min-width: 1px;
          background: #8996bf;
        }
      }
      .video-star {
        margin-bottom: 10px;
        .video-score {
          // min-width: 149px;
          width: 45%;
        }
        .w100p {
          width: 100%;
        }
        .video-divider {
          margin: 0 8px 0 6px;
        }
        > div {
          display: flex;
          align-items: center;
        }
      }
      .disinh {
        display: inherit;
        .video-score {
          width: 100%;
        }
        .flexd {
          display: flex;
          align-items: center;
          .van-rate {
            position: relative;
            bottom: 1px;
          }
        }
        .mb10 {
          margin-bottom: 10px;
          position: relative;
          .posid {
            position: absolute;
            top: 0;
            right: 0;
            font-weight: 400;
            font-size: 12px;
            color: #006afe;
          }
          .pr-9 {
            font-size: 16px;
            font-weight: 600;
            color: #8996bf;
            margin-right: 4px;
          }
          .enpr9 {
            width: 90px;
            flex: none;
          }
          .huase {
            font-size: 16px;
            font-weight: 500;
            color: #ffca3a;
            margin-left: 5px;
          }
        }
      }
      .ai-star {
        .ai-score {
          width: 45%;
        }
        .divider {
          margin-right: 8px;
          margin-left: 6px;
        }
      }
    }
    .base-nfo_tags {
      color: #8996bf;
    }

    .tags {
      width: 94%;
      margin: 10px auto 0 auto;
    }

    .nofinish {
      display: block;
      .warning {
        line-height: 50px;
        i {
          font-size: 50px;
        }
        text-align: center;
      }
      .text {
        font-size: 16px;
        text-align: center;
      }
    }
  }

  .tabs-list {
    // position: sticky;
    // top: 0;
    width: 100%;
    background-color: #ffffff;
  }

  .tabfixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .fix-tabs-list {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 0.3rem 0 0 0;
    z-index: 10;
  }

  .tabs {
    list-style-type: none;
    display: flex;
    overflow-x: auto;
    li {
      font-weight: 500;
      padding: 0.3rem 0rem;
      text-align: center;
      font-size: 0.38rem;
      color: #333333;
      white-space: nowrap;
      flex: 1 0 auto;
      div {
        padding: 0 0.2rem;
      }
    }
    li:not(:last-child) {
      div {
        border-right: 1px solid rgba(230, 230, 230, 1);
      }
    }

    .active {
      color: #14b1ff;
    }
    // .tab {
    //   flex: 1;
    //   text-align: center;
    // }
  }

  .small-tabs {
    list-style-type: none;
    display: flex;
    position: relative;
    border-bottom: 1px solid rgba(240, 240, 240, 1);
    //margin-top: 0.5rem;
    padding: 0.2rem 0;
    li {
      flex: 1;
      text-align: center;
      font-size: 0.38rem;
      color: #cccccc;
      padding: 0.1rem 0;
    }

    .tabs__line {
      transition-duration: 0.3s;

      width: 2rem;
      position: absolute;
      bottom: 0px;
      left: 0;
      z-index: 1;
      height: 3px;
      background-color: #14b1ff;
      border-radius: 3px;
    }

    .active {
      color: #14b1ff;
    }
  }
  .disnone {
    display: none;
  }

  .small-tabs2 {
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    border-bottom: 1px solid rgba(240, 240, 240, 1);
    //margin-top: 0.5rem;
    padding: 0.2rem 0;
    overflow: scroll;
    li {
      min-width: 2rem;
      text-align: center;
      font-size: 0.38rem;
      color: #cccccc;
      padding: 0.1rem 0;
    }

    .tabs__line {
      transition-duration: 0.3s;

      width: 1.6rem;
      position: absolute;
      bottom: 0px;
      left: 0;
      z-index: 1;
      height: 3px;
      background-color: #14b1ff;
      border-radius: 3px;
    }

    .active {
      color: #14b1ff;
    }
  }

  .small-tabs2::-webkit-scrollbar {
    display: none;
  }

  .tab-content {
    width: calc(100% - 16px);
    padding-bottom: 2rem;
    margin: auto;
  }
}
.show_datepicker {
  text-align: center;
  padding-top: 20px;
  position: relative;
  img {
    margin-bottom: 20px;
  }
  .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .accept {
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .is_interview {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #dddddd;
  }
  .nomore {
    margin-left: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .btns {
    display: flex;
    justify-content: space-around;
    .van-button {
      width: 140px;
    }
  }
}

#mark_label_1::after,
#mark_label_2::after {
  content: attr(data-num);
  position: relative;
  // padding: 2px 5px;
  display: inline-block;
  text-align: center;
  position: relative;
  font-size: 15px;
}
#allEstimateList::after {
  content: attr(data-num);
  position: relative;
  height: 18px;
  // padding: 2px 5px;
  background: #f56c6c;
  color: #ffffff;
  border-radius: 9999px;
  line-height: 18px;
  text-align: center;
  // position: relative;
  font-size: 8px;
  top: -50px;
  left: 20px;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  padding: 2px 6px;
}
.hidden-data-num::after {
  display: none !important;
}
.labelscore-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 120px;
  line-height: 12px;
  font-size: 12px;
  padding: 10px;
  .labelscore-item_label {
    font-weight: 400;
    color: #8996bf;
  }
  .labelscore-item_value {
    font-weight: 600;
    color: #333333;
  }
}
.repeat-interview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f9fe;
  padding: 10px;
  // margin-bottom: 10px;
  .re-left {
    font-size: 16px;
    font-weight: bold;
    // padding-left: 20px;
    // border-left: 3px solid #1890ff;
    line-height: 16px;
  }
}
.base-info .van-rate {
  flex-wrap: nowrap;
}
.nowrap {
  font-size: 13px;
  white-space: nowrap;
}
</style>
