import service from '@/config/http'
import qs from 'qs'

//获取总共的评估
export const selectAllEstimate = data => {
  const req = {
    url: '/mark/employ/estimate/selectAllEstimate',
    params: data,
  }
  return service(req)
}

// 面试报告
export const reportNew = data => {
  const req = {
    url: '/mark/interview/interview/reportNew',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}

//获取总共的评估
export const selectAllEstimateGroupBy = data => {
  const req = {
    url: '/mark/employ/estimate/selectAllEstimateGroupBy',
    params: data,
  }
  return service(req)
}
//获取招商推荐记录
export const selectAllEstimateGroupByforCMBC = data => {
  const req = {
    url: '/mark/employ/estimate/selectAllEstimateGroupByforCMBC',
    params: data,
  }
  return service(req)
}
// 获取评价记录未读次数
export const selectEstimateGroupByTimePeriod = data => {
  const req = {
    url: '/mark/employ/estimate/selectEstimateGroupByTimePeriod',
    params: data,
  }
  return service(req)
}
// 获取评价记录未读次数
export const clickEstimate = data => {
  const req = {
    url: '/mark/employ/estimate/clickEstimate',
    params: data,
  }
  return service(req)
}
/**
 * meritas报告接口
 * @param params
 * @returns
 */
export const getMeritasReport = params => {
  return service({
    url: '/mark/interview/interview/meritasReport',
    method: 'get',
    params,
  })
}

/**
 * 防作弊记录接口
 * @param params
 * @returns
 */
export const getAntiCheatingInfo = params => {
  return service({
    url: '/mark/interview/interview/preventCheatingReport',
    method: 'get',
    params,
  })
}

/**
 * 获取防作弊日志
 * @param data
 * @returns
 */
export const getAntiCheatingLogs = (params) => {
  return service({
    url: '/mark/interview/report/getOperatorLogs',
    method: 'get',
    params,
  })
}

/**
 * 获取防作弊监控照片（电脑监控、手机监控、屏幕监控）
 * @param data
 * @returns
 */
export const getAntiCheatingImages = (params) => {
  return service({
    url: '/mark/interview/report/getChatMonitorImg',
    method: 'get',
    params,
  })
}

/**
 * 获取语言成绩信息
 * 
 * 该函数通过调用接口'/mark/report/languageScore/getLanguageScore'来获取语言成绩信息。
 * 使用GET方法发送请求，并可选地传递参数。
 * 主要用于在前端获取语言成绩数据，以便进一步的展示或处理。
 * 
 * @param params 可选参数对象，用于传递查询条件等信息。
 * @returns 返回一个Promise，包含服务端返回的数据。
 */

export const getLanguageScore = (params) => {
  return service({
    url: '/mark/report/languageScore/getLanguageScore',
    method: 'get',
    params,
  })
}
